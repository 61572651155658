import { useAuth } from "@clerk/clerk-react";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const SCREENSHOT_ENDPOINT = process.env.REACT_APP_SCREENSHOT_ENDPOINT;


const HelperFunctions = () => {
    const { getToken } = useAuth()

    const fetchFunctions = {
        fetchUser: (email) => fetchUser(email, getToken),
        listSubscriptionsByEmail: (email) => listSubscriptionsByEmail(email, getToken),
        listOwnedByEmail: (email) => listOwnedByEmail(email, getToken),
        listSiftsByEmail: (email) => listSiftsByEmail(email, getToken),
        fetchSift: (videoId, platform) => fetchSift(videoId, platform, getToken),
        fetchCommentData: (groupId, postId) => fetchCommentData(groupId, postId, getToken),
        fetchCommentDataByPostId: (postId) => fetchCommentDataByPostId(postId, getToken),
        fetchAccountAvatar: (platform, username) => fetchAccountAvatar(platform, username, getToken),
        screenShotComments: (html) => screenShotComments(html, getToken),
        fetchCommenterProfiles: (profiles) => fetchCommenterProfiles(profiles, getToken),
    };

    const userManagementFunctions = {
        updateUserSettings: (email, settings) => updateUserSettings(email, settings, getToken),
        createUser: (email, name) => createUser(email, name, getToken),
        unlinkUserTeam: (userId, teamId) => unlinkUserTeam(userId, teamId, getToken),
        linkUserTeam: (userId, teamId) => linkUserTeam(userId, teamId, getToken),
        addMemberToTeam: (teamId, email) => addMemberToTeam(teamId, email, getToken),
        removeMemberFromTeam: (teamId, userId) => removeMemberFromTeam(teamId, userId, getToken),
        getUserTeamData: (userId) => getUserTeamData(userId, getToken),
    };

    const teamFunctions = {
        getTeamData: (id) => getTeamData(id, getToken),
        updateTeamData: (teamId, data) => updateTeamData(teamId, data, getToken),
        createTeam: (name) => createTeam(name, getToken),
        getTeamUsageData: (teamId, start, end) => getTeamUsageData(teamId, start, end, getToken),
        getTeammateData: (teamId) => getTeammateData(teamId, getToken),
        linkDrop: (links, teamId, groupIds) => linkDrop(links, teamId, groupIds, getToken),
        linkDropQueue: (links, teamId, groupIds) => linkDropQueue(links, teamId, groupIds, getToken),
        getReportData: (postIds) => getReportData(postIds, getToken),
        createCampaign: (name, teamId) => createCampaign(name, teamId, getToken),
        createGroup: (name, teamId, campaignId) => createGroup(name, teamId, campaignId, getToken),
        updateGroupName: (name, groupId) => updateGroupName(name, groupId, getToken),
        updatePostGroups: (postId, groupIds) => updatePostGroups(postId, groupIds, getToken),
        
    };

    const postFunctions = {
        updatePostGroups: (postId, groupIds) => updatePostGroups(postId, groupIds, getToken),
        updatePostTags: (postId, teamId, tags) => updatePostTags(postId, teamId, tags, getToken),
        updatePostsGroups: (postIds, groupIds) => updatePostsGroups(postIds, groupIds, getToken),
        summarizeComments: (posts, analytics, filterType) => summarizeComments(posts, analytics, filterType, getToken),
        searchCommentsByPosts: (topic, filters, postIds, groupIds) => searchCommentsByPosts(topic, filters, postIds, groupIds, getToken),
        searchCommentsByPost: (topic, filters, postId, groupIds) => searchCommentsByPost(topic, filters, postId, groupIds, getToken),
        checkAccount: (username, platform) => checkAccount(username, platform, getToken),
        getPostVibeCheck: (postId) => getPostVibeCheck(postId, getToken),
    };

    return {
        ...fetchFunctions,
        ...userManagementFunctions,
        ...teamFunctions,
        ...postFunctions,
    };
};

export default HelperFunctions;

async function fetchCommenterProfiles(profiles, getToken){

    console.log("fetching commenter profiles...", profiles);

    let req = {
        profiles: profiles
    };

    return await fetch(`${ENDPOINT}/fetchcommenterprofiles`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data.data;
        }).catch((err) => console.log(err));
}

async function getPostVibeCheck(postId, getToken){

    let req = {
        postId: postId
    };

    return await fetch(`${ENDPOINT}/getpostvibecheck`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data.data;
        }).catch((err) => console.log(err));
}


async function summarizeComments(posts, analytics, filterType, getToken){

    console.log("summarizing comments");
    // console.log("posts", posts);
    let req = {
        posts: posts,
        analytics: analytics,
        filterType: filterType
    };

    return await fetch(`${ENDPOINT}/summarize`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));


}

async function removeMemberFromTeam(teamId, userId, getToken){

    let req = {
        teamId: teamId,
        userId: userId
    };

    return await fetch(`${ENDPOINT}/removememberfromteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));
}

async function addMemberToTeam(teamId, email, getToken){

    let req = {
        email: email,
        teamId: teamId
    };

    return await fetch(`${ENDPOINT}/addmembertoteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));
}

async function createUser(email, name, getToken){
        
            let req = {
                email: email,
                name: name
            };
        
            return await fetch(`${ENDPOINT}/createuser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
                .then((data) => {
        
                    // console.log("data", data);
        
                    if (data && data.data) {
                        return data.data;
                    }
                }).catch((err) => console.log(err));
        
        }   

async function unlinkUserTeam(userId, teamId, getToken){
        
            let req = {
                userId: userId,
                teamId: teamId,
            };
        
            return await fetch(`${ENDPOINT}/unlinkuserteam`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
                .then((data) => {
        
                    // console.log("data", data);
        
                    if (data && data.data) {
                        return data.data;
                    }
                }).catch((err) => console.log(err));
        
        }




async function linkUserTeam(userId, teamId, getToken){
    


        let req = {
            userId: userId,
            teamId: teamId,
            status: "active",
            role: "admin"
        };
    
        return await fetch(`${ENDPOINT}/linkuserteam`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

    async function getReportData(postIds, getToken){

        let req = {
            postIds: postIds
        };
    
        return await fetch(`${ENDPOINT}/getreportdata`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

async function createTeam(name, getToken){

    let req = {
        name: name,
    };

    return await fetch(`${ENDPOINT}/createteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function updateGroupName(name, groupId, getToken){
    
        let req = {
            groupId: groupId,
            name: name
        };
    
        return await fetch(`${ENDPOINT}/updategroupname`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

    async function linkDrop(links, teamId, groupIds, getToken){
            
        let req = {
            links: links,
            teamId: teamId,
            groupIds: groupIds
        };

        console.log("req", req);
    
        return await fetch(
            // `${ENDPOINT}/ldqueue/`
            `${ENDPOINT}/linkdrop`
            
            , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }



    async function linkDropQueue(links, teamId, groupIds) {
        // Organize links by platform
        const platformLinks = {};
    
        links.forEach(link => {
            const platform = getPlatformFromLink(link); // Assume this function extracts the platform from the link
            if (!platformLinks[platform]) {
                platformLinks[platform] = [];
            }
            platformLinks[platform].push(link);
        });
    
        // Send each platform's links to their corresponding endpoint
        const promises = Object.keys(platformLinks).map(platform => {
            let req = {
                links: platformLinks[platform],
                teamId: teamId,
                groupIds: groupIds
            };
    
            console.log("req for platform", platform, req);
    
            return fetch(`https://citv7wn2f9.execute-api.us-east-1.amazonaws.com/dev/ldqueue/${platform}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",

                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
              .then((data) => {
                  console.log("data for platform", platform, data);
                  return data && data.data ? data.data : null;
              }).catch((err) => console.log(err));
        });
    
        return Promise.all(promises); // Wait for all requests to complete
    }
    
    // Helper function to extract platform from link
    function getPlatformFromLink(link) {
        if (link.includes("instagram.com")) {
            return "Instagram";
        } else if (link.includes("tiktok.com")) {
            return "TikTok";
        }
    else if (link.includes("youtube.com")) {
        return "YouTube";

    }
    else if (link.includes("linkedin.com")) {
        return "LinkedIn";

    }
    else if (link.includes
    ("twitter.com")) {
        return "Twitter";
    }
    else{
        return "TikTok";
    }

}
export async function getTeammateData(teamId, getToken){
    
    let req = {
        teamId: teamId
    };

    return await fetch(`${ENDPOINT}/getteammatedata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

export async function getTeamUsageData(teamId, start, end, getToken){
    let req = {
        teamId: teamId,
        start: start,
        end: end
    };

    return await fetch(`${ENDPOINT}/getteamusagedata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function exportCommentImage(selectedCommentData, getToken){

    let req = {
        comments: selectedCommentData
    };

    return await fetch(`${SCREENSHOT_ENDPOINT}/screenshot`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function createGroup(name, teamId, campaignId, getToken){

    let req = {
        name: name,
        teamId: teamId,
        type:"group",
        campaignId: campaignId
    };

    return await fetch(`${ENDPOINT}/creategroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function createCampaign(name, teamId, getToken){

    let req = {
        name: name,
        teamId: teamId,
        type:"campaign"
    };

    return await fetch(`${ENDPOINT}/creategroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updatePostGroups(postId, groupIds, getToken){

    let req = {
        postId: postId,
        groupIds: groupIds
    };

    return await fetch(`${ENDPOINT}/updatepostgroups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updateTeamData(teamId, data, getToken){

    let req = {
        teamId: teamId,
        data: data
    };

    return await fetch(`${ENDPOINT}/updateteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function updatePostTags(postId, teamId, tags, getToken){

    let req = {
        postId: postId,
        teamId: teamId,
        tags: tags
    };

    return await fetch(`${ENDPOINT}/updateposttags`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updatePostsGroups(postIds, groupIds, getToken){

    let req = {
        postIds: postIds,
        groupIds: groupIds
    };

    return await fetch(`${ENDPOINT}/updatepostsgroups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function getUserTeamData(userId, getToken){

    let req = {
        userId: userId
    };

    return await fetch(`${ENDPOINT}/getuserteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}



async function getTeamData(id, getToken) { // Accept token as a parameter

    let req = {
        id: id
    };

    return await fetch(`${ENDPOINT}/getteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            //console.log("data", data);
            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function screenShotComments(html, getToken){
    
        let req = {
            html: html
        };
    
        return await fetch(`${ENDPOINT}/screenshot`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }


async function fetchAccountAvatar(platform, username, getToken){

    let req = {
        platform: platform,
        username: username,
        imgType: "avatar"
    };

    return await fetch(`${ENDPOINT}/fetchimg`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function fetchCommentData(groupId, getToken){

    let req = {
        groupId: groupId,
    };

    return await fetch(`${ENDPOINT}/fetchcommentdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function fetchCommentDataByPostId(postId, getToken){

    let req = {
        postId: postId
    };

    return await fetch(`${ENDPOINT}/fetchcommentdatabypostid`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function searchCommentsByPosts(topic, filters, postIds, groupIds, getToken){
    

    let req = {
        groupIds: groupIds,
        topic: topic,
        postIds: postIds,
        filters: filters
    };

    return await fetch(`${ENDPOINT}/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function searchCommentsByPost(topic, filters, postId, groupIds, getToken){
    

    let req = {
        groupIds: groupIds,
        topic: topic,
        postId: postId,
        filters: filters
    };

    return await fetch(`${ENDPOINT}/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function checkAccount(username, platform, getToken){

    let req = {
        username: username,
        platform: platform
    };

    return await fetch(`${ENDPOINT}/checkaccount`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}


async function listSubscriptionsByEmail(email, getToken){
    
    console.log("fetching subscriptions for " + email);

    let req = {
        email: email
    };

    return await fetch(`${ENDPOINT}/listsubscriptionsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }

            else {
                return [];
            }
        }).catch((err) => console.log(err));


}


async function listOwnedByEmail(email, getToken){

console.log("fetching subscriptions for " + email);

let req = {
    email: email
};

return await fetch(`${ENDPOINT}/listownedbyemail`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

    },
    body: JSON.stringify(req),
}).then((res) => res.json())
    .then((data) => {

        //console.log("data", data);

        if (data && data.data) {
            return data.data;
        }
        else{
            return [];
        }
    }).catch((err) => console.log(err));


}


    
async function fetchUser(email, getToken){

    let req = {
        email: email
    };
  
    return await fetch(`${ENDPOINT}/getuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
    .then((data) => {
          //console.log("data", data);
          if (data && data.data) {
            let userData = data.data;
            return userData;
  }}).catch((err) => console.log(err));
  
  }


  async function listSiftsByEmail(email, getToken){

    console.log("fetching sifts...");

    let req = {
        email: email
    };


    return await fetch(`${ENDPOINT}/listsiftsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {

                const response = {
                    sifts: data.data
                };

                return response;
            }
        }).catch((err) => console.log(err));

}

async function fetchSift(videoId, platform, getToken){

    console.log("fetching sift...");
  
    let req = {
      postId: videoId,
      platform: platform
    };
  
    return await fetch(`${ENDPOINT}/getsift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

      },
      body: JSON.stringify(req),
    }).then((res) => res.json())
      .then((data) => {
  
        //console.log("data", data);
  
        if (data && data.data && data.data.sift) {
  
          const response = {
            fetchedSift: JSON.parse(data.data.sift),
            fetchedData: data.data,
            fetchedCommentData: JSON.parse(data.data.commentData),
            totalComments: data.data.totalComments,
          };
  
          return response;
        }
      })
  
      .catch((err) => {
        console.log("err", err);
      });
  
  }
  
  async function updateUserSettings(email, settings, getToken){
  
    let req = {
        email: email,
        settings: settings
    };
  
    return await fetch(`${ENDPOINT}/updateuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
  
    .catch((err) => console.log(err));
  
  }
  