import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  import Functions from "./HelperFunctions";
  import mixpanel from 'mixpanel-browser';


  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const darkMode = false;
  const theme = createTheme(siftsyTheme);
  
  export default function SiftsyAuthProvider(props) {
    const { isLoaded, isSignedIn, user } = useUser();
    const [empty, setEmpty] = React.useState(true);
    const { teamId } = props;

    React.useEffect(() => {
    
        async function fetchData() {

            let userId = await fetchUserId();
            if (!userId){
              console.log("no id");
              return;
            }
        console.log("userId", userId);

        if(teamId){

            let data = await Function.getUserTeamData(userId);
            let teamIds = data.map(team => team.teamId);
            if(!teamIds.includes(teamId)){
                setEmpty(true);
                return;
            }
        }
        else{

          //get team data by userId
        //   let data = await Functions.getUserTeamData(userId);
        //   setTeamData(data);
        //   console.log("teamData", data);
          setEmpty(false);
        }
        }

        async function fetchUserId(){
            let email = user.primaryEmailAddress.emailAddress;
            console.log("email", email);
            let siftsyUser = await Functions.fetchUser(email);
            console.log("siftsyUser", siftsyUser);



            mixpanel.identify(siftsyUser.id);

            console.log("siftsyUser", siftsyUser);
            console.log("mixpanel", mixpanel);  
   
            mixpanel.people.set({ '$name': siftsyUser.name,
                                  '$email': email,
                                                                    // Add anything else about the user here
                                  });




            return siftsyUser.id;
        }
    
        if(user){
            fetchData();
            }

        console.log("team data updated");

      }, [user]);
  
    return (
              !empty ? 

              props.children

              : 
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    mt: 10,
                  }}
                >
                  <Typography variant="h6" align="center">
                    {" "}
                    This page is not available.
                  </Typography>

                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: "12px",
                      background: "#EEEAFF",
                      p: 3,
                      m: 2,
                    }}
                  >
                    <Typography variant="body1">
                      Please make sure you are signed in to the correct account
                      and have access to this page.
                    </Typography>
                  </Card>
                </Box>
                </ThemeProvider>
    );
    
  }
  




