import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Toolbar,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    Add,
    AutoAwesome,
    BarChart,
    Chat,
    CheckBoxOutlined,
    Close,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FolderOutlined,
    GroupOutlined,
    OpenInNew,
    VideoFileOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  import SiftsyAuthProvider from "./SiftsyAuthProvider";
  import { useNavigate } from "react-router-dom";

  import HelperFunctions from "./HelperFunctions";
import GroupView from "./GroupView";
import { formatData, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import Footer from "./Footer";
import AddNewModal from "./AddNewModal";
import axios from 'axios';


  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const darkMode = false;
  const theme = createTheme(siftsyTheme);

  export default function Dashboard(props) {
    const { isLoaded, isSignedIn, user } = useUser();
    const [teamData, setTeamData] = React.useState(null);
    const [allPostData, setAllPostData] = React.useState(null);
    const [empty, setEmpty] = React.useState(true);
    const [selectedAssets, setSelectedAssets] = React.useState({team: 0, group: 0, post: 0, report: 0});
    const [addNewOpen, setOpenAddNew] = React.useState(false);
    const [typeToAdd, setTypeToAdd] = React.useState("group");
    const [selectedPostData, setSelectedPostData] = React.useState([]);
    const [copied, setCopied] = React.useState(false);  
    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
    const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight);
    const [selectedTeam, setSelectedTeam] = React.useState(null);
    const [teammateData, setTeammateData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const Functions = HelperFunctions();


    const handleSelectedTeamChange = (e, index) => {
      setSelectedTeam(e.target.value);
    };

    React.useEffect(() => {
      const fetchTeammateData = async () => {
        let data = await Functions.getTeammateData(selectedTeam.id);
        setTeammateData(data);
      }
      if(selectedTeam){
      fetchTeammateData();
      }
    }, [selectedTeam]);
       


React.useEffect(() => {
  const handleResize = () => {
    setPageWidth(window.innerWidth);
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

   
    React.useEffect(() => {
    
        async function fetchData() {
          setLoading(true);
            let id = await fetchUserId();
            if (!id){
              console.log("no id");
              return;
            }

          console.log("id", id);
          //get team data by userId
          let data = await Functions.getUserTeamData(id);
          setTeamData(data);
          setSelectedTeam(data[0]);
          console.log("teamData", data);
          setEmpty(false);

        }

        async function fetchUserId(){
            let email = user.primaryEmailAddress.emailAddress;
            console.log("email", email);
            let siftsyUser = await Functions.fetchUser(email);
            console.log("siftsyUser", siftsyUser);
            return siftsyUser.id;
        }
    
        if(user){
            fetchData();
            }

        console.log("team data updated");

        setLoading(false);
        
      
      }, [user]);


      const handleSelectedPostData = useCallback((data) => {
        setSelectedPostData(data);
      }, []);


    const updateSelectedAssets = useCallback((type, index) => {
  
      let newSelected = {...selectedAssets};
      newSelected[type] = index;
      setSelectedAssets(newSelected);
    }, []);

    const openAddNew = useCallback((type, open) => {
      setOpenAddNew(open);
      setTypeToAdd(type);
       }, []);
      
  
    return (
      // <SiftsyAuthProvider>
    // <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <div
            style={{
              backgroundColor: "#f7f8fa",
              width: viewportWidth,
              height: viewportHeight,
              overflowX: "hidden",
            }}
          >
            <AppBar
              position="static"
              elevation={0}
              sx={{ background: "#fff", borderBottom: "1px solid #ddd" }}
              id="app-bar"
            >
              <Toolbar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "flex-stretch",
                  alignItems: "center",
                  width: pageWidth > 1200 ? 1200 : pageWidth,
                  overflow:
                    viewportWidth < 900 || viewportHeight < 1200
                      ? "auto"
                      : "hidden",
                  margin: "auto",
                }}
              >
                <Box>
                  <ButtonBase>
                    {/* onClick={() => window.open(`/`, "_blank")}> */}
                    <img
                      src={"/siftsy_logo.png"}
                      alt="logo"
                      id="logo"
                      style={{ width: 80, height: "auto", padding: "5px" }}
                    />
                    {/* <Typography
                      color="primary"
                      align="center"
                      variant={"subtitle2"}
                      sx={{
                        borderRadius: 30,
                        background: "rgba(238,234,255, .8)",
                        color: "#4828C5",
                        padding: "1px 12px 1px 12px",
                        ml: -1,
                        fontWeight: 600,
                      }}
                    >
                      beta{" "}
                    </Typography> */}
                  </ButtonBase>
                </Box>
                <Box sx={{ flexGrow: 1 }} />

                {/* <Box>
                <ProfileSettingsButton
                  preferredFilter={preferredFilter}
                  savePreferredFilter={savePreferredFilter}
                />
              </Box> */}

                <Box >
                  <UserButton />
                </Box>
              </Box>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                margin: "0 auto",
                width: pageWidth > 1200 ? 1200 : pageWidth,
                overflow:
               viewportHeight < 1200
                    ? "auto"
                    : "hidden",
                transition: "all 0.3s ease",

              }}
            >

              
              <Box sx={{ display: "flex", flexDirection: "column", p:4, pt:2, pb:2 }}>
              {/* {selectedTeam ? selectedTeam.id : null} */}
              <Grid container spacing={3}>
  {loading ? (
    <Grid item xs={12}>
      <Skeleton variant="rectangular" height={200} borderRadius={3} width="100%" />
    </Grid>
  ) : teamData && teamData.length > 0 ? (
    teamData.map((team, index) => (
      <Grid item xs={12} sm={6} md={4} key={team?.id}>
        <Card
          elevation={2}
          sx={{
            borderRadius: 3,
            p: 3,
            height: 180,
            display: 'flex',
            flexDirection: 'column',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
              {team?.name.charAt(0).toUpperCase()}
            </Avatar>
            <Typography variant="h5" sx={{ fontWeight: 700, color: 'text.primary' }}>
              {team?.name}
            </Typography>
          </Box>
          {/* <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {team.description || 'No description available'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <GroupOutlined sx={{ height: 20, width: 20, mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2" color="text.secondary">
              {team.memberCount || 0} members
            </Typography>
          </Box> */}
          <Box sx={{flexGrow:1}} />
          <Button
            variant="contained"
            color="primary"
            endIcon={<OpenInNew />}
            fullWidth
            sx={{borderRadius: 20}}
            onClick={() => {
              console.log(`Opening team ${team.id}`);
              navigate(`/team/${team.id}`);
            }}
          >
            View Team
          </Button>
        </Card>
      </Grid>
    ))
  ) : (
    <Grid item xs={12}>
      <Typography variant="h6" color="text.secondary" align="center">
        No teams available
      </Typography>
    </Grid>
  )}
</Grid>
{/* <Select value={selectedTeam} onChange={handleSelectedTeamChange}>
  {teamData ? (
    teamData.map((team, index) => (
      <MenuItem value={team}>{team.name}</MenuItem>
    ))
  ) : (
    <MenuItem></MenuItem>
  )}
</Select>


                  {selectedTeam && teammateData ? 

<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
  
            {teammateData.map((member, index) => {
              return (
                <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center", justifyContent:"flex-start", mt:2}}>
                  <Box sx={{display:"flex", flexDirection:"column"}}>
                  <Typography variant="body1" fontWeight={600} fontSize={18}>
                    {member.name}
                  </Typography>
                  <Typography variant="body1" fontWeight={600} fontSize={14}>
                    {member.email}
                  </Typography>
                  </Box>
                </Box>
              );
            }
            )}
</Box>

                  : null}

                  <CreateTeamAndLinkUsers teamId={selectedTeam ? selectedTeam.id : null} /> */}

               
              </Box>
        

              <Footer />
     
            </Box>
          </div>
        </ThemeProvider>
        // </ErrorBoundary>
      // </SiftsyAuthProvider>
    );
  }

//create a basic error boundary
// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     logErrorToMyService(error, errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props.children;
//   }
// }

  

function CreateTeamAndLinkUsers(props) {
  const Functions = HelperFunctions();

  const [teamName, setTeamName] = useState('');
  const [users, setUsers] = useState([{ name: '', email: '', role: 'admin', status: 'active' }]);
  const { teamId } = props;

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const handleUserNameChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].name = e.target.value;
    setUsers(newUsers);
  };

  const handleUserEmailChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].email = e.target.value;
    setUsers(newUsers);
  };

  const handleUserRoleChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].role = e.target.value;
    setUsers(newUsers);
  };

  const handleUserStatusChange = (e, index) => {
    const newUsers = [...users];
    newUsers[index].status = e.target.value;
    setUsers(newUsers);
  };

  const addNewUserField = () => {
    setUsers([...users, { name: '', email: '', role: '', status: '' }]);
  };

  const handleCreateTeamAndUsers = async () => {
    try {
      // Create a new team
      // const teamResponse = await Functions.createTeam(teamName);
      // const createdTeamId = teamResponse.data.id;
      // setTeamId(createdTeamId);

      alert(teamId);

      for (const user of users) {
        // Create each user
        let userData = await Functions.fetchUser(user.email);

        console.log("userData", userData);

        if (!userData) {
          const userResponse = await Functions.createUser(user.email, user.name);
          userData = userResponse;
        }
        await Functions.linkUserTeam(userData.id, teamId);
   
      }

      

      alert('Team created, users created, and linked successfully!');
    } catch (error) {
      console.error('Error creating team, users, or linking:', error);
      alert('Failed to create team, users, or link them.');
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      {/* <Typography variant="h4" gutterBottom>
        Create New Team
      </Typography>
      <TextField
        label="Team Name"
        variant="outlined"
        value={teamName}
        onChange={handleTeamNameChange}
        fullWidth
        margin="normal"
      /> */}
      <Typography variant="h5" gutterBottom>
        Link Users to Team
      </Typography>
      {users.map((user, index) => (
        <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="User Name"
              variant="outlined"
              value={user.name}
              onChange={(e) => handleUserNameChange(e, index)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="User Email"
              variant="outlined"
              value={user.email}
              onChange={(e) => handleUserEmailChange(e, index)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Role"
              variant="outlined"
              value={user.role}
              onChange={(e) => handleUserRoleChange(e, index)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Status"
              variant="outlined"
              value={user.status}
              onChange={(e) => handleUserStatusChange(e, index)}
              fullWidth
            />
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" color="primary" onClick={addNewUserField}>
        Add Another User
      </Button>
      <Box sx={{ marginTop: 4 }}>
        <Button variant="contained" color="secondary" onClick={handleCreateTeamAndUsers}>
          Create Team and Link Users
        </Button>
      </Box>
    </Box>
  );
}
