import {
  Box,
  Grid,
  Typography,
  Card,
  createTheme,
  ButtonBase,
  Tabs,
  Tab,
  Avatar,
  Button,
  Paper,
  IconButton,
  CardMedia,
  Chip,
  Grow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Popper,
  ClickAwayListener,
  Skeleton,
  Icon,
  Tooltip,
  Divider,
  Popover,
  Fade,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  Add,
  AnalyticsOutlined,
  ArrowBackIos,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AssessmentOutlined,
  BackHand,
  BackspaceOutlined,
  BarChartOutlined,
  CalculateOutlined,
  Check,
  Close,
  CommentOutlined,
  Download,
  ExpandMore,
  FastRewindOutlined,
  FavoriteBorder,
  FilterList,
  Fullscreen,
  FullscreenExitOutlined,
  Group,
  LineAxisOutlined,
  LocalActivityRounded,
  NumbersOutlined,
  OpenInNew,
  OpenInNewOutlined,
  Percent,
  PieChartOutlined,
  Redo,
  Refresh,
  Replay,
  Reply,
  SendOutlined,
  ShowChartOutlined,
  ShowChartRounded,
  StackedLineChartOutlined,
  TimelineOutlined,
  VisibilityOutlined,
  CopyAllOutlined,
  Work,
  CheckCircle,
  Chat,
} from "@mui/icons-material";
import { siftsyTheme } from "./siftsytheme";
import { useUser, UserButton } from "@clerk/clerk-react";
import {
  formatData,
  formatNumber,
  formatPlatform,
  UTCtoLocal,
  formatFullDate,
  estimateViews
} from "./Helpers";
import CommentView from "./CommentView";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import HelperFunctions from "./HelperFunctions";
import { initial, set } from "lodash";
import html2canvas from "html2canvas";
import Footer from "./Footer";
import ContentMetrics from "./ContentMetrics";
import SelectChips from "./SelectChips";
import ExportMetricsModal from "./ExportMetricsModal";
import VibeCheckSection from "./VibeCheckSection";
import { BarChart } from "recharts";
import "./TableStyles.css";
import ContentTagPicker from "./ContentTagPicker";
import GroupPicker from "./GroupPicker";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { ArrowPathIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, ArrowTopRightOnSquareIcon, ChartBarIcon, ChartBarSquareIcon, ChatBubbleBottomCenterTextIcon, ChatBubbleLeftRightIcon, DocumentDuplicateIcon, EyeIcon, EyeSlashIcon, FolderArrowDownIcon, RectangleStackIcon, Square2StackIcon, Square3Stack3DIcon, TableCellsIcon } from "@heroicons/react/24/outline";
let ENV = process.env.REACT_APP_ENV;

export default function GroupView(props) {
  const Functions = HelperFunctions();

  const { isLoaded, isSignedIn, user } = useUser();

  const {
    teamData,
    viewportHeight,
    viewportWidth,
    selectedPostData,
    handleSelectedPostData,
    resetPosts,
    refreshData,
    loading,
    backToDash,
    groupView,
    setGroupView,
    selectedGroups,
    forwardToPost,
    mobileTab,
    selectedPost,
    softRefresh,
    selectedTags,
    selectedAccounts
  
  } = props;

  const metricsRef = React.useRef(null);

  const small = viewportHeight < 900;
  const [localGroupView, setLocalGroupView] = useState(groupView); // Add this new state

  React.useEffect(() => {
    setLocalGroupView(groupView);
  }, [groupView]);

  const [empty, setEmpty] = React.useState(true);
  const [selectedComments, setSelectedComments] = React.useState([]);
  const [exportMetrics, setExportMetrics] = React.useState(false);
  const [fullTable, setFullTable] = React.useState(() => {
    const savedFullTable = localStorage.getItem('fullTable');
    return savedFullTable ? JSON.parse(savedFullTable) : false;
  });  const [exporting, setExporting] = React.useState(false);
  const [selectedPostRows, setSelectedPostRows] = React.useState([]);
  const [minimizeMetrics, setMinimizeMetrics] = React.useState(() => {
    const savedMinimizeMetrics = localStorage.getItem('minimizeMetrics');
    return savedMinimizeMetrics ? JSON.parse(savedMinimizeMetrics) : false;
  });  const updateGroupView = React.useCallback((view) => {
    requestAnimationFrame(() => {
      setGroupView(view);
    });
  }, []);

  const [hoveredThumbnail, setHoveredThumbnail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const tableRef = React.useRef(null);
  const [copied, setCopied] = React.useState(false);
  const selectedRowsRef = React.useRef([]); // Ref to store the current selection
  const [openPopper, setOpenPopper] = React.useState(false);
  const [quickFilterText, setQuickFilterText] = React.useState("");
  const [commentData, setCommentData] = React.useState([]);
  const [hideNoComments, setHideNoComments] = React.useState(false);


  const handleRowHover = (target, thumbnail) => {
    setHoveredThumbnail(thumbnail);
    //anchor at top left corner of the table
    //get position of the mouse

    // let element = document.querySelector(`[row-index="${event.rowIndex}"]`);
    setAnchorEl(target);
    console.log("target", target);
  };

  const handleClosePopper = () => {
    setHoveredThumbnail(null);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    selectedPostData.length > 0 ? setEmpty(false) : setEmpty(true);
    // updateGroupView("comments");
  }, [selectedPostData]);

  const goToPost = React.useCallback(
    (postId) => {
      forwardToPost(postId);
      // updateGroupView("comments");
    },
    [teamData]
  );

  const handleMinimizeMetrics = React.useCallback((state) => {
    setMinimizeMetrics(state);
    setFullTable(state);
    localStorage.setItem('minimizeMetrics', JSON.stringify(state));
    localStorage.setItem('fullTable', JSON.stringify(state));
  }, []);

  const handleSetFullTable = React.useCallback((state) => {
    setFullTable(state);
    setMinimizeMetrics(state);
    localStorage.setItem('fullTable', JSON.stringify(state));
    localStorage.setItem('minimizeMetrics', JSON.stringify(state));
  }, []);

const handleExportMetrics = React.useCallback((state) => {
    setExportMetrics(state);
  } , []);

  const handleSelectedComments = React.useCallback((comments) => {
    setSelectedComments(comments);
  }, []);

  const handleSelectedPostRows = React.useCallback((rows) => {
    setSelectedPostRows(rows);
  }, []);

  const onSelectionChanged = React.useCallback(() => {
    const selectedNodes = tableRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    // console.log("selectedData", selectedData);
    selectedRowsRef.current = selectedData;
    
  }, []);

  // const copyTableDataToClipBoard = () => {
  //   if (tableRef.current) {
  //     const gridApi = tableRef.current.api;
  //     gridApi.copySelectedRangeToClipboard();

  //     setCopied(true);
  //     setTimeout(() => {
  //       setCopied(false);
  //     }
  //     , 3000);
  //   }


  // };

  console.log("commentData", commentData);


  const copyTableDataToClipBoard = () => {
    if (!tableRef.current || !tableRef.current.api) {
      console.error('Table reference is not set or API is not available.');
      return;
    }

    const headers = [
      'Account', 
      'Username', 
      'Campaigns', 
      'Tags', 
      'Vibe Score',
      'Sentiment',
      'Positive Sentiment %',
      'Neutral Sentiment %',
      'Negative Sentiment %',
      'Platform', 
      'Views', 
      'Comments', 
      'Likes', 
      'Shares', 
      'Saves'
    ];
    

    const allNodes = tableRef.current.api.getRenderedNodes(); // Get all nodes
    const allData = allNodes.map(node => ({
      // id: node.data.id, // Uncomment if needed
      account: node.data.account,
      username: node.data.username,
      campaigns: node.data.groupIds
        .map(group => {
          const foundGroup = teamData.groups.find(g => g.id === group);
          return foundGroup ? foundGroup.name : "";
        })
        .filter(Boolean) // To remove empty strings
        .join(", "), // Joining campaign names as a comma-separated string
        tags: JSON.parse(node.data.tags).join(", "),
        vibeScore: node.data.vibeScore.toFixed(1),
        sentiment: `${node.data.sentimentData?.positivePercentage?.toFixed(0) + "%"}/${node.data.sentimentData?.neutralPercentage?.toFixed(0) + "%"}/${node.data.sentimentData?.negativePercentage?.toFixed(0) + "%"}`,
        positiveSentimentPercentage: node.data.sentimentData?.positivePercentage?.toFixed(0) + "%",
        neutralSentimentPercentage: node.data.sentimentData?.neutralPercentage?.toFixed(0) + "%",
        negativeSentimentPercentage: node.data.sentimentData?.negativePercentage?.toFixed(0) + "%",
      platform: node.data.platform,
      views: node.data.views,
      comments: node.data.comments,
      likes: node.data.likes,
      shares: node.data.shares,
      saves: node.data.saves
      // Add any other properties you need
    }));

    const csvData = [
      headers, // Add the headers row first
      ...allData.map(item => [
        item.account,
        item.username,
        item.campaigns,
        item.tags,
        item.vibeScore,
        item.sentiment,
        item.positiveSentimentPercentage,
        item.neutralSentimentPercentage,
        item.negativeSentimentPercentage,
        item.platform,
        item.views,
        item.comments,
        item.likes,
        item.shares,
        item.saves
      ])
    ];
    
    const csvString = csvData.map(row => Object.values(row).join(',')).join('\n');
    
    navigator.clipboard.writeText(csvString).then(() => {
      console.log('Data copied to clipboard');
    }).catch(err => {
      console.error('Error copying data: ', err);
    });

          setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }
      , 3000);
    
  };
  
  const isMobile = viewportWidth < 900;

  return !empty ? (
    <Grid container spacing={2} sx={{ p: isMobile ? .5 : 2, pt: 0 }}>
       <Grid
            item
            xs={12}
            md={minimizeMetrics? 12 : 5}
            lg={minimizeMetrics? 12 : 4}
            sx={{ pb: minimizeMetrics ? 0 :5, display: !isMobile ? "inline-block" : "none" }}
          > 
    
            <>
          {!minimizeMetrics && (
            <Box
              sx={{
                display: "flex",
                height: isMobile ? "40px" : "50px",
                flexDirection: "row",
              }}
            >
              <Tabs>
                <Tab
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                      <ChartBarIcon style={{height:20, color:"#4728c4"}}/>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#4728c4",
                        fontWeight: 700,
                        textTransform: "none",
                        fontSize: isMobile ? 14 : 16,
                      }}
                    >
                      Metrics
                    </Typography>
                    </Box>
                    
                  }
                  value={true}
                />
              </Tabs>
            </Box>

          )}
   <Grow in={true}>
            <Box
              sx={
                !minimizeMetrics && {
                overflowY: "scroll",
                height:
                  viewportWidth < 900
                    ? "calc(100vh - 195px)"
                    : "calc(100vh - 224px)",
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "transparent",
                },
              }}
            >
              <ContentMetrics
              setMinimizeMetrics={handleMinimizeMetrics}
              minimizeMetrics={minimizeMetrics}
                handleExportMetrics={handleExportMetrics}
                selectedPostData={selectedPostData}
                teamData={teamData}
                refreshData={refreshData}
                isMobile={isMobile}
                softRefresh={softRefresh}
                small={viewportHeight < 900}
                viewportHeight={viewportHeight}
              />

              {!small && !minimizeMetrics &&
              <>
              <Footer />
              <br />
              <br /> <br />
              <br /> <br />
              <br />
              <ExportMetricsModal
                open={exportMetrics}
                onClose={() => setExportMetrics(false)}
                selectedPostData={selectedPostData}
                teamData={teamData}
                selectedGroups={selectedGroups}
              />
              </>
              }
            </Box>
            </Grow>
        </>

    
  </Grid>
      <Grid item xs={12} md={fullTable || minimizeMetrics ? 12 : 7} lg={fullTable || minimizeMetrics ? 12 : 8}>
        <Box sx={{ height: "fit-content" }}>
          <Box
            sx={{
              display: "flex",
              height: isMobile ? "40px" : "50px",
              flexDirection: "row",
            }}
          >
            {selectedPostData.length > 1 || isMobile ? (
              <Tabs
                value={localGroupView}
                onChange={(event, newValue) => {
                  // Update local state immediately
                  setLocalGroupView(newValue);
                  // Then update parent state
                  setGroupView(newValue);
                }}
                sx={{ height: isMobile ? "40px" : "50px",
                
                 }}
                 TabIndicatorProps={{
                  style: { 
                    top: 0,
                  }
                }}
                //place indicator on top of the tab
       
              >
                {isMobile && (
                  <Tab
                    value="metrics"
                    label={
                      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                      <ChartBarIcon style={{height:20, color: groupView == "metrics" ? "#4728c4" : "#888"}}/>
                      <Typography
                        variant="body1"
                        sx={{
                          color: groupView == "metrics" ? "#4728c4" : "#888",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                          height: 45,
                        }}
                      >
                        {" "}
                        Metrics
                      </Typography>
                      </Box>
                    }
                  />
                )}
                <Tab
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                    <ChatBubbleLeftRightIcon style={{height:20, color: groupView == "comments" ? "#4728c4" : "#888"}}/>
                    <Typography
                      variant="body1"
                      sx={{
                        color: groupView == "comments" ? "#4728c4" : "#888",
                        fontWeight: 700,
                        textTransform: "none",
                        fontSize: isMobile ? 14 : 16,
                        height: 45,
                      }}
                    >
                      Comments
                      {/* {`Comments (${formatNumber(
                        selectedPostData
                          .map((post) => post.data.engagement ? post.data.engagement.comments : 0)
                          .reduce((a, b) => a + b, 0)
                      )})`} */}
                    </Typography>
                    </Box>
                  }
                  value="comments"
                />
                {selectedPostData.length > 1 && (
                  <Tab
                    label={
                      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>

                      <Square3Stack3DIcon style={{height:20, color: groupView == "posts" ? "#4728c4" : "#888"}}/>
                      <Typography
                        variant="body1"
                        sx={{
                          color: groupView == "posts" ? "#4728c4" : "#888",
                          fontWeight: 700,
                          textTransform: "none",
                          fontSize: isMobile ? 14 : 16,
                          height: 45,
                        }}
                      >
                        Posts
                        {" "}
                        {selectedPostData.length > 0
                          ? `(${selectedPostData.length})`
                          : ""}
                      </Typography>
                      </Box>
                    }
                    value="posts"
                  />
                )}
              </Tabs>
            ) : (
              <Box sx={{ display: "flex", height: isMobile ? "40px" : "50px" }}>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    ml: 2,
                    color: "#4728c4",
                    fontWeight: 700,
                    textTransform: "none",
                    fontSize: isMobile ? 14 : 16,
                  }}
                >
                  {`Comments (${formatNumber(
                    selectedPostData
                      .map((post) => post.data.engagement.comments)
                      .reduce((a, b) => a + b, 0)
                  )})`}
                </Typography>
              </Box>
            )}



            <Box sx={{ flexGrow: 1 }} />

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!isMobile && groupView === "posts" && selectedPostData.length > 1 && (
                  <>

                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{border: "1px solid #ccc", borderRadius: 2, background:"#fff"}}
                    placeholder="Search Posts"
                    value={quickFilterText}
                    onInput={(e) => setQuickFilterText(e.target.value)}
                  />
                  <Tooltip title="Export Post Data as CSV">
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (
                          tableRef &&
                          tableRef.current &&
                          tableRef.current.api
                        ) {
                          tableRef.current.api.exportDataAsCsv();
                        }
                      }}
                    >
                      <FolderArrowDownIcon style={{height:20, color:"#5C6078"}}/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy Data">
                    {copied ?

                    <IconButton disabled>
                      <CheckCircle color="#089616"/>
                    </IconButton>

                    :
                  <IconButton onClick={copyTableDataToClipBoard}>
                    <DocumentDuplicateIcon style={{height:20, color:"#5C6078"}}/>
                  </IconButton>
                    }
                </Tooltip>
                  </>
                )}
          
      
                <Tooltip title="Refresh Data">
                  <IconButton onClick={refreshData}>
                    <ArrowPathIcon style={{height:20, color:"#5C6078"}}/>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {viewportWidth > 900 ? (
              <Tooltip title={fullTable ? "Minimize View" : "Maximize View"}>
                <IconButton onClick={() => handleSetFullTable(!fullTable)}>
                  {fullTable ? <ArrowsPointingInIcon style={{height:20, color:"#5C6078"}}/> : <ArrowsPointingOutIcon style={{height:20, color:"#5C6078"}} />}
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>

          {/* {groupView == "metrics" && viewportWidth < 900 && ( */}
            <Box
              sx={{
                display: groupView == "metrics" && viewportWidth < 900 ? "block" : "none",
                overflowY: "scroll",
                height:
                  viewportWidth < 900
                    ? "calc(100vh - 195px)"
                    : "calc(100vh - 224px)",
              }}
            >
              <ContentMetrics
                handleExportMetrics={handleExportMetrics}
                selectedPostData={selectedPostData}
                teamData={teamData}
                refreshData={refreshData}
                isMobile={isMobile}
              />
              <Footer />
              <br />
              <br /> <br />
              <br /> <br />
              <br />
              <ExportMetricsModal
                open={exportMetrics}
                onClose={() => setExportMetrics(false)}
                selectedPostData={selectedPostData}
                teamData={teamData}
                selectedGroups={selectedGroups}
              />
            </Box>
            {/* <SelectChipsPopper
                selectedRows={selectedRowsRef.current}
                teamData={teamData}
                refreshData={refreshData}
                softRefresh={softRefresh}
                viewportWidth={viewportWidth}
                openPopper={openPopper}
                
              /> */}
            <Box sx={{display: (groupView === "comments" || selectedPostData.length == 1) ? "block" : "none"}}>
            <CommentView
              teamData={teamData}
              viewportHeight={viewportHeight}
              viewportWidth={viewportWidth}
              selectedPostData={selectedPostData}
              selectedComments={selectedComments}
              handleSelectedComments={handleSelectedComments}
              selectedGroups={selectedGroups}
              goToPost={goToPost}
              fullTable={fullTable}
              selectedTags={selectedTags}
              selectedAccounts={selectedAccounts}
              commentData={commentData}
              setCommentData={setCommentData}
              isMobile={isMobile}
      
            /> 
            </Box>
{/* 
          {groupView === "comments" ? (

            
          ) : (
            <>
           

              <Paper elevation={0} sx={{ width: "100%", overflowX: "auto" }}>
                {loading && (
                  <Box
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      height: viewportHeight - 300,
                      borderRadius: 2,
                      border: "1px solid #ccc",
                      scroll: "none",
                    }}
                  >
                    <Box sx={{ height: 55, background: "#eee" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: viewportHeight / 2,
                        gap: 2,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={100} />
                    </Box>
                  </Box>
                  
                )}
              </Paper>
            </>
          )} */}
          {(groupView == "posts" && selectedPostData.length > 1) && (
      
                    <PostTable
                    quickFilterText={quickFilterText}
                    selectedPostRows={selectedPostRows}
                    setSelectedPostRows={handleSelectedPostRows}
                      selectedPostData={selectedPostData}
                      teamData={teamData}
                      refreshData={refreshData}
                      softRefresh={softRefresh}
                      hideNoComments={hideNoComments}
                      tableRef={tableRef}
                      onSelectionChanged={onSelectionChanged}
                      isMobile={isMobile}
                      goToPost={goToPost}
                      viewportHeight={viewportHeight}
                      viewportWidth={viewportWidth}
                      height={fullTable ? "calc(100vh - 325px)" : "calc(100vh - 230px)"}
                      loading={loading}
                      copyTableDataToClipBoard={copyTableDataToClipBoard}
                    />
                  )}
              
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap:4,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        m: 4,
      }}
    >
      <Typography variant="h6" align="center" sx={{ color: "#888" }}>
        No posts here... yet!
        <Typography variant="body1" align="center" sx={{ color: "#888" }}>
        Add posts or adjust filter settings above to view posts.
      </Typography>
      </Typography>
  
      <Button
        
        variant="contained"
        sx={{
          borderRadius: 30,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 20px",
          fontSize: 20,
        }}
        startIcon={<Add />}
        onClick={() => props.openAddNew("post", true)}
      >
        Add Posts
      </Button>
      
      {/* <Box sx={{ display: "flex", overflow:"hidden", borderRadius:8, boxShadow: 10,
      background:"#000",
        height: Math.min(viewportWidth - 50, 500),
        width: Math.min(viewportWidth - 50, 500),
       }}>
         <iframe 
        src="https://drive.google.com/file/d/16WwSCk6-lkUh8X2_hTvdgB8hM1nCn03q/preview" 
        width={Math.min(viewportWidth - 50, 500)}
        height={Math.min(viewportWidth - 50, 500)}
        allow="autoplay; encrypted-media" 
        allowFullScreen
      ></iframe>
       </Box> */}
                   

    </Box>
  );
}

function dateComparator(date1, date2) {
  var date1Timestamp = new Date(date1).getTime();
  var date2Timestamp = new Date(date2).getTime();

  if (date1Timestamp < date2Timestamp) {
    return -1;
  } else if (date1Timestamp > date2Timestamp) {
    return 1;
  } else {
    return 0;
  }
}

const MiniScorer = ({ score }) => {
  let color =
    score >= 7
      ? "#089616"
      : score >= 5
      ? "#74d52e"
      : score >= 3
      ? "#ffb93d"
      : "#fb3e3e";

  const svgStyle = {
    height: "140px",
    width: "140px",
    position: "absolute",
    transform: "rotate(-85deg)",
    fill: "transparent",
    top: 0,
    left: 0,
  };

  const circleStyle = {
    fill: "transparent",
  };

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    // Change number value to shift the progress bar // 130 = 100, 440 = 0
    strokeDashoffset: score == 10 ? 130 : 440 - (310 / 10) * score + 8,
  };

  const trackStyle = {
    stroke: color,
    opacity: 0.5,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    strokeDashoffset: "130", // Change number value to shift the progress bar
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      {score ?
      <Avatar
        sx={{
          width: "40px",
          height: "40px",
          color: color,
          background: "transparent",
          fontSize: "1rem",
          fontWeight: 900,
        }}
      >
        {score}
      </Avatar>
      :
      <Typography sx={{ fontSize: 12, m: 1, fontWeight: 700, color:"#aaa" }}>
        N/A
      </Typography>
}

      {/* <svg style={svgStyle}>
        <circle id="track" cx="80" cy="60" r="50" style={trackStyle} />
        <circle
          id="progress-border"
          cx="80"
          cy="60"
          r="50"
          style={{ ...circleStyle, ...progressBorderStyle }}
        />
      </svg> */}
    </Box>
  );
};







const PostTable = (props) => {

  const { viewportWidth, hideNoComments, selectedPostData, loading, height, setSelectedPostRows, selectedPostRows, teamData, refreshData, softRefresh, tableRef, onSelectionChanged, isMobile, goToPost, viewportHeight, quickFilterText } = props;

  const Functions = HelperFunctions();

  const [openMultiplePopper, setOpenMultiplePopper] = React.useState(false);
  const [rowData, setRowData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const updateCampaigns = (newCampaign) => {
    const updatedRows = selectedPostRows.map(row => ({
      ...row,
      campaign: newCampaign,
    }));
    // Update the grid data with the new campaigns
    tableRef.current.api.applyTransaction({ update: updatedRows });
  };

  const updateTags = (newTags) => {
    const updatedRows = selectedPostRows.map(row => ({
      ...row,
      tags: newTags,
    }));
    // Update the grid data with the new tags
    tableRef.current.api.applyTransaction({ update: updatedRows });
  };
  const [isDataReady, setIsDataReady] = useState(false);

  //update table data when rowData is updated

  // React.useEffect(() => {
  //   alert("rowData updated");
  // }, [rowData]);

  const handleRowClick = React.useCallback((event) => {
    // Add console.log to debug
    console.log("Row clicked event:", event);
    
    // Ignore clicks if they're on buttons, links, or other interactive elements
    if (event.event.target.closest('button, a, .interactive-element')) {
      console.log("Clicked on interactive element, ignoring row click");
      return;
    }
    
    console.log("Processing row click for post:", event.data.id);
    goToPost(event.data.id);
  }, [goToPost]);
  
  const calculateEngagementRate = (post) => {
    let views = post.data.engagement.views || 0;
    let comments = post.data.engagement.comments || 0;
    let likes = post.data.engagement.likes || 0;
    let shares = post.data.engagement.shares || 0;
    let saves = post.data.engagement.saves || 0;
    let platform = post.data.platform;

    if (platform === "linkedin" && comments >= 0 && likes >= 0 && shares >= 0 && saves >= 0) {
      views = estimateViews(likes, comments, shares, saves, platform);
    }

    let rate = (((likes + comments + shares + saves) * 100) / views);
    return isNaN(rate) || !isFinite(rate) ? -1 : rate;
  };

  React.useEffect(() => {
    // setRowData([]);
    async function fetchData() {
      if (!selectedPostData || selectedPostData.length === 0) {
        return; // No need to reset rowData or selectedRows
      }
  
      const allRowData = []; // Temporary array to accumulate data
      const batchSize = 100; // Define the number of posts to fetch in each batch
      const totalPosts = selectedPostData.length;
  
      for (let i = 0; i < totalPosts; i += batchSize) {
        // Create a batch of post IDs
        const batch = selectedPostData.slice(i, i + batchSize);
  
        // Fetch all data in parallel for the current batch
        const results = await Promise.allSettled(
          batch.map(post => getRowData(post.id))
        );

        results.forEach(result => {
          if (result.status === 'fulfilled' && result.value !== null) {
            allRowData.push(result.value);
          } else {
            console.error("Error fetching data:", result.reason);
          }
        });
  
        // Update row data incrementally after each batch
        setRowData(prevRowData => {
          console.log('Previous rowData:', prevRowData);
          const combinedData = [...prevRowData, ...allRowData];
          const uniqueData = Array.from(
            new Map(combinedData.map(item => [item.id, item])).values()
          );
          console.log('New rowData:', uniqueData);
          return uniqueData;
        });
      }
  
      // Removed the line that resets selected rows
      // setSelectedRows([]); // Reset selected rows
    }
  
    // Define getRowData as a separate function
    const getRowData = async (postId) => {
      try {
        const post = selectedPostData.find(p => p.id === postId);

        console.log("post", post);

        const vibeCheck = post?.data?.vibeCheckData ? post?.data?.vibeCheckData : await Functions.getPostVibeCheck(postId);
        // const vibeCheck = await Functions.getPostVibeCheck(postId);

        const numComments = post?.data?.engagement?.comments ? post?.data?.engagement?.comments : 0;

        if (hideNoComments && numComments === 0) {
          return null;
        }

        return {
          id: post.id,
          avatar: post.data.userAvatar,
          thumbnail: post.data.thumbnail,
          account: post.data.creatorName,
          username: post.data.username,
          vibeScore: vibeCheck?.vibeScore,
          sentimentData: vibeCheck?.sentimentData,
          relevanceData: vibeCheck?.relevanceData,
          consensusData: vibeCheck?.consensusData,
          positiveSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.positivePercentage : null,
          neutralSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.neutralPercentage : null,
          negativeSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.negativePercentage : null,
          siftedAt: post.createdAt ? formatFullDate(post.createdAt) : "",
          tags: post.data.tags,
          platform: formatData(post.data.platform),
          postType: post.data.postType,
          date: formatFullDate(post.data.postedAt),
          engagementRate: calculateEngagementRate(post),
          views: post.data.engagement.views > 0
            ? post.data.engagement.views
            : estimateViews(
                post.data.engagement.likes,
                post.data.engagement.comments,
                post.data.engagement.shares,
                post.data.engagement.saves,
                post.data.platform
              ),
          comments: numComments,
          likes: post.data.engagement.likes,
          shares: post.data.engagement.shares,
          saves: post.data.engagement.saves ? post.data.engagement.saves : 0,
          groupIds: post.groupIds ? post.groupIds : [],
          link: post.link ? post.link : "",
        };
      } catch (error) {
        console.error("Error fetching row data:", error);
        return null; // Return null for failed fetch
      }
    }
  
    fetchData();

  }, [selectedPostData, refreshData, softRefresh, hideNoComments]);

  const gridOptions = useMemo(() => ({
    enableCellTextSelection: true,
    ensureDomOrder: true,
    suppressColumnVirtualisation: true,
    animateRows: false,
    rowHeight: isMobile ? 50 : 60,
  }), []);

  const handleSelectionChanged = () => {
    const selectedRows = tableRef.current.api.getSelectedRows(); // Extract selected rows
    // console.log("selectedRows", selectedRows);
    setSelectedRows(selectedRows); // Update the selected rows state
      };

  const columnDefs = useMemo(() => {
    return [
      // { field: "id", hide: ENV !== "dev", width:50 },
      //       {
      //   field: "post",
      //   headerName: "",
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      //   showDisabledCheckboxes: true,
      //   width:50
      // },



      {
        headerName: "",
        pinned: "left",
        sortable: false,
        cellRenderer: (props) => {
          return (
            <IconButton
              sx={{ height: 25, width: 25 }}
              onClick={() => {
                goToPost(props.data.id);
              }}
            >
                                <ArrowTopRightOnSquareIcon style={{ height: 20, width: 20, color:"#5C6078" }} />

              <OpenInNewOutlined
                sx={{ height: 20, width: 20 }}
              />
            </IconButton>
          );
        },
        width: 60,
      },
      {
        field: "account",
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          return params.value ? params.value.replace(/\s\s+/g, " ") : ""; // Check if params.value is defined
        },
        width: 220,
        cellRenderer: (props) => {
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                  overflow: "hidden",
                  width: 200,
                }}
              >
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={props.data.avatar}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography>
                    {props.data.account}
                  </Typography>
                  <Typography variant="body2">
                    @{props.data.username?.replace("@", "")}
                  </Typography>
                </Box>
              </Box>
            </>
          );
        },
      },
      {
        field: 'link',
        headerName: 'Link',
        cellRenderer: (params) => {
          return (
            <a href={params.value} target="_blank">
              Link
            </a>
          );
        },
        cellRendererParams: {
          target: '_blank',
        },
        width: 65
      },
      {
        field: "groupIds",
        headerName: "Campaigns",
        cellStyle: { display: "flex", alignItems: "center" },
  
        //if cell is clicked, update cell renderer
        valueFormatter: (params) => {
          return Array.isArray(params.value) ? params.value.map((group) => {
            return teamData.groups.find(
              (g) => g.id == group
            ) ? teamData.groups.find((g) => g.id == group).name : "No Campaign";
          }) : []; // Return an empty array if params.value is not an array
        },

        cellRenderer: (props) => {
          return  <Box sx={{width: 180}}>
          <GroupPicker
          data={{groupIds: props.data.groupIds}}
          refreshData={refreshData} softRefresh={softRefresh} {...props} teamData={teamData} cellView 
          postIds={[props.data.id]} />
          </Box>;
        },
        // },
        width: 180,
      },
      
      {
        field: "tags",
        headerName: "Tags",
        width: 180,
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          if (params.value) {
            try {
              return JSON.parse(params.value).map((tag) => tag);
            } catch (e) {
              console.error("Error parsing tags:", e);
              return []; // Return an empty array or a fallback value
            }
          }
          return []; // Return an empty array if params.value is undefined
        },
        cellRenderer: (props) => {
          return <Box sx={{ width: 180 }}><ContentTagPicker teamData={teamData}
            refreshData={refreshData}
            post={{ id: props.data.id, data: props.data }}
            softRefresh={softRefresh}
            cellView
          /></Box>
        },
      },
      {
        field: "vibeScore",
        cellStyle: { display: "flex", alignItems: "center" },
        valueGetter: (params) => params.data.vibeScore != null ? params.data.vibeScore.toFixed(1) : 0,
        cellRenderer: (props) => {
          return (props.data.comments > 0 && props.data.vibeScore && props.data.vibeScore > 0) ? (
            <MiniScorer score={props.data.vibeScore.toFixed(1)} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                position: "relative",
                p: 1,
              }}
            >
              <Tooltip title="Vibe Score is not available for posts with no comments.">
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#888",
                }}
              >
                N/A
              </Typography>
              </Tooltip>
            </Box>
          );
        },
        width: 110,
      },
      {
        field: "sentimentData",
        headerName: "Sentiment",
        sortable:false,
        valueGetter: (params) => 
          (params.data.sentimentData?.positivePercentage || params.data.sentimentData?.neutralPercentage || params.data.sentimentData?.negativePercentage) ?
          `${params.data.sentimentData.positivePercentage.toFixed(0)}% / ${params.data.sentimentData.neutralPercentage.toFixed(0)}% / ${params.data.sentimentData.negativePercentage.toFixed(0)}%` 
          : "N/A",
        cellRenderer: (props) => {
          return <Box sx={{display: "flex", flexDirection: "row", gap: .5, alignItems: "center", height:55}}>
                     {props.data.sentimentData?.positivePercentage > 0 && 
          <Chip 
            sx={{ 
              fontWeight: 'bold', 
              color: '#fff', 
              backgroundColor: '#6dbf75', 
              fontWeight: 500,
              paddingTop: 0.3,
              width: `${Math.max(props.data.sentimentData?.positivePercentage, 30)}%` // Set width based on percentage with a minimum of 10%
            }}  
            label={props.data.sentimentData?.positivePercentage?.toFixed(0) + "%"} 
            color="success" 
            size="small" 
          />
        }
        {props.data.sentimentData?.neutralPercentage > 0 && 
          <Chip 
            sx={{ 
              fontWeight: 'bold', 
              color: '#fff',
              backgroundColor: '#f9b64e', 
              fontWeight: 500,
              paddingTop: 0.3,
              width: `${Math.max(props.data.sentimentData?.neutralPercentage, 30)}%` // Set width based on percentage with a minimum of 10%
            }} 
            label={props.data.sentimentData?.neutralPercentage?.toFixed(0) + "%"} 
            color="warning" 
            size="small" 
          />
        }
        {props.data.sentimentData?.negativePercentage > 0 && 
          <Chip 
            sx={{ 
              fontWeight: 'bold', 
              backgroundColor: '#fc8b8f',
              fontWeight: 500,
              paddingTop: 0.3,
              width: `${Math.max(props.data.sentimentData?.negativePercentage, 30)}%` // Set width based on percentage with a minimum of 10%
            }} 
            label={props.data.sentimentData?.negativePercentage?.toFixed(0) + "%"} 
            color="error" 
            size="small" 
          />
        }
          </Box>;
          ;
        },
        width: 180,
      },
      {
        field: "positiveSentimentPercentage",
        headerName: "Positive % (Sentiment)",
        valueGetter: (params) => params.data.positiveSentimentPercentage != null ? params.data.positiveSentimentPercentage.toFixed(0) : null, // Ensure it can be accessed for export
        hide: false,
        width: 80,
        sortable:true,
        comparator: (a, b) => {
          if (a === null) return -1; // Move null to the beginning
          if (b === null) return 1;  // Move null to the beginning
          return a - b; // Standard comparison for numbers
        },
        cellRenderer: (props) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center", height: 55 }}>
              <Typography fontSize={props.data.positiveSentimentPercentage != null ? 16 : 12} color={props.data.negativeSentimentPercentage != null ? "#000" : "#888"}>
              {props.data.positiveSentimentPercentage != null 
                  ? props.data.positiveSentimentPercentage.toFixed(0) + "%" 
                  : "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "neutralSentimentPercentage",
        headerName: "Neutral % (Sentiment)",
        valueGetter: (params) => params.data.neutralSentimentPercentage != null ? params.data.neutralSentimentPercentage.toFixed(0) : null,
        hide: false,
        width: 80,
        sortable:true,
        comparator: (a, b) => {
          if (a === null) return -1; // Move null to the beginning
          if (b === null) return 1;  // Move null to the beginning
          return a - b; // Standard comparison for numbers
        },

        cellRenderer: (props) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center", height: 55 }}>
              <Typography fontSize={props.data.neutralSentimentPercentage != null ? 16 : 12} color={props.data.neutralSentimentPercentage != null ? "#000" : "#888"}>
              {props.data.neutralSentimentPercentage != null 
                  ? props.data.neutralSentimentPercentage?.toFixed(0) + "%" 
                  : "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "negativeSentimentPercentage",
        headerName: "Negative % (Sentiment)",
        valueGetter: (params) => params.data.negativeSentimentPercentage != null ? params.data.negativeSentimentPercentage.toFixed(0) : null,
        hide: false,
        width: 80,
        sortable:true,
        comparator: (a, b) => {
          if (a === null) return -1; // Move null to the beginning
          if (b === null) return 1;  // Move null to the beginning
          return a - b; // Standard comparison for numbers
        },
        cellRenderer: (props) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5, alignItems: "center", height: 55 }}>
              <Typography fontSize={props.data.negativeSentimentPercentage != null ? 16 : 12} color={props.data.negativeSentimentPercentage != null ? "#000" : "#888"}>
                {props.data.negativeSentimentPercentage != null 
                  ? props.data.negativeSentimentPercentage?.toFixed(0) + "%" 
                  : "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "platform",
        width: 110,
        cellRenderer: (props) => {
          return (
            <Typography variant="body1" fontWeight={600}>
              {props.data.platform}
            </Typography>
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "engagementRate",
        valueFormatter: (params) => {
          return params.value < 0 ? "N/A" : params.value?.toFixed(1) + "%";
        },
        cellRenderer: (props) => {
          // engagement rate as percentage
          return (
            //check if engagement rate is a number or is infinte
          
               <Typography variant={props.data.engagementRate < 0 ? "body2" : "body1"}
                 fontWeight={600} color={props.data.engagementRate < 0 ? "#888" : "#000"}>
              {props.data.engagementRate < 0 ? "N/A" : props.data.engagementRate?.toFixed(1) + "%"}{props.data.platform == "LinkedIn" 
              // || (props.data.platform == "Instagram" && props.data.postType == "feed")
               ? "*" : ""}
              </Typography>
            
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 130,
         
        // cellRenderer: (props) => {
        //   return <MiniScorer score={props.data.vibeScore} />;
        // },
        // width:110
      },
      {
        field: "views",
        editable: true,
        cellRenderer: (props) => {
          return props.data.views == 0 || isNaN(props.data.views) || !isFinite(props.data.views)

           ? (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 14,
                color: "#888",
              }}
            >
              N/A
            </Typography>
          ) 

          : (
            <Typography>
              {formatNumber(props.data.views)}{props.data.platform == "LinkedIn" 
              // || (props.data.platform == "Instagram" && props.data.postType == "feed") 
              ? "*" : ""}
            </Typography>
          )
        
        },
        cellStyle: { display: "flex", alignItems: "center" },
        valueFormatter: (params) => {
          return params.value == 0 || isNaN(params.value) || !isFinite(params.value) ? "N/A" : params.value.toFixed(0);
        },
        width: 110,
      },
      {
        field: "likes",
        cellRenderer: (props) => {
          return (  isNaN(props.data.likes) || !isFinite(props.data.likes)

          ? (
           <Typography
             sx={{
               fontWeight: 600,
               fontSize: 12,
               color: "#888",
             }}
           >
             N/A
           </Typography>
         ) : (
            <Typography>
              {formatNumber(props.data.likes)}
            </Typography>
         )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "comments",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.comments) || !isFinite(props.data.comments)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.comments)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "shares",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.shares) || !isFinite(props.data.shares)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.shares)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field: "saves",
        cellRenderer: (props) => {
          return (
            isNaN(props.data.saves) || !isFinite(props.data.saves)

            ? (
             <Typography
               sx={{
                 fontWeight: 600,
                 fontSize: 12,
                 color: "#888",
               }}
             >
               N/A
             </Typography>
           ) : (
              <Typography>
                {formatNumber(props.data.saves)}
              </Typography>
           )
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {field:"postType",
        valueGetter: (params) => params.data.postType == "reel" ? "REELS" : params.data.postType == "short" ? "SHORTS" : params.data.postType.toUpperCase(),
      cellRenderer: (props) => {
        return <Chip 
        sx={{
          fontWeight: 600,
          fontSize: 10,
          color: "#333",
        }}
        label={(props.data.postType == "reel" ? "Reels" : props.data.postType == "short" ? "shorts" : props.data.postType).toUpperCase()} />;
      },
    
      cellStyle: { display: "flex", alignItems: "center" },
      width: 110,
      },
      {
        field: "date",
        sortable: true,
        comparator: dateComparator,
        cellRenderer: (props) => {
          return <Typography>{props.data.date}</Typography>;
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
      },
      {
        field:"siftedAt",
        sortable: true,
        comparator: dateComparator,
        cellRenderer: (props) => {
          return <Typography>{props.data.siftedAt}</Typography>;
        },
        cellStyle: { display: "flex", alignItems: "center" },
        width: 110,
        sort:"desc"
      }
    ]
  }, []);


 return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
      <CircularProgress />
    </Box>
  ) : rowData.length === 0 ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
      <CircularProgress />
    </Box>
  ) : selectedPostData.length === 0 ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={viewportHeight - (isMobile ? 200 : 300)}>
      <Typography>No data available</Typography>
    </Box>
  ) : (
    <div
      className={`ag-theme-quartz data-table`} // applying the grid theme
      style={{
        height: viewportHeight - (isMobile ? 200 : 350),
        width: "100%",
        background: "transparent",
      }}
      id="data-table"
    >
      <AgGridReact
        ref={tableRef}
        cacheQuickFilter={true}
        // onRowClicked={handleRowClick}
        suppressCellSelection={true}  // Changed to false to allow row clicks
        suppressRowClickSelection={false}  // Changed to false to allow row selection on click
        rowSelection="multiple" 
        suppressScrollOnNewData={true}
        deltaRowDataMode={true}  // Enables delta updates
        getRowId={(params) => params.data.id}  // Unique identifier for each row
        animateRows={true} 
        quickFilterText={quickFilterText}
        enableRangeSelection={true}
        clipboardDeliminator={','}
        defaultCsvExportParams={{
          fileName: `Siftsy-Export-${new Date().toLocaleDateString()}`,
          columnSeparator: ",",
        }}
        gridOptions={gridOptions}
        rowData={rowData}
        columnDefs={columnDefs}
        rowBuffer={20}
        maxBlocksInCache={10}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={2}
        infiniteInitialRowCount={100}
        pagination={true}
        paginationPageSize={isMobile ? null : 100}
        suppressPaginationPanel={false}
        paginationAutoPageSize={isMobile ? true : false}
        paginationNumberFormatter={(params) => 
          isMobile ? params.value : params.value.toLocaleString()
        }
        onSelectionChanged={handleSelectionChanged}
      />
      {/* {selectedRows.length > 0 && (
        <Popper
          open={selectedRows.length > 0}
          anchorEl={document.getElementById("data-table") ? document.getElementById("data-table") : null}
          placement="top-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [-20, -210],
              },
            },
          ]}
          style={{
            zIndex: 1000,
            transform: 'translate(0, -100%)',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
            borderRadius: 10,
          }}
        >
          <div
            style={{
              borderRadius: 10,
              padding: 10,
              background: "#fff",
              width: "calc(30vw)", 
              height: 150,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {`Update ${selectedRows.length} post${selectedRows.length > 1 ? "s" : ""}`}
            </Typography>
            <SelectChipsPopper
              selectedRows={selectedRows}
              teamData={teamData}
              refreshData={refreshData}
              softRefresh={softRefresh}
              viewportWidth={viewportWidth}
            />
          </div>
        </Popper>
      )} */}
    </div>
  );

}


//create a popper with a selectchips component for when rows are selected in the table
//this will allow users to update the campaign of multiple posts at once


function SelectChipsPopper(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selectedRows, teamData, refreshData, softRefresh, viewportWidth } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setAnchorEl(null);
    //set anchorel to full window
    setAnchorEl(
      viewportWidth < 900
        ? document.body
        : document.getElementById("data-table")
    );
  }, []);


  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverPosition = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const getCommonGroupIds = (selectedRows) => {
    if (selectedRows.length === 0) return [];
  
    // Get the groupIds from the first row
    let commonGroupIds = selectedRows[0].groupIds;
  
    // Iterate over each row and filter the common groupIds
    selectedRows.forEach((row) => {
      commonGroupIds = commonGroupIds.filter(groupId => row.groupIds.includes(groupId));
    });
  
    return commonGroupIds;
  };

  return (
    <div>
      {/* <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableEnforceFocus
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 1,
            background: "#111",
            ...popoverPosition,
          },
        }}
      > */}
        <Box
          sx={{
            pointerEvents: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
            <GroupPicker
  data={{ groupIds: getCommonGroupIds(selectedRows) }} // Only common groupIds
  // data={{groupIds: props.data.groupIds}}
              teamData={teamData}
              refreshData={refreshData}
              softRefresh={softRefresh}
              postIds={selectedRows.map((row) => row.id)}
            />
          {/* <IconButton onClick={deselectAllComments}>
            <Close sx={{ color: "#fff" }} />
          </IconButton> */}
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, color: "#fff" }}
          >{`${selectedRows.length} selected`}</Typography>

          <Box sx={{ ml: viewportWidth < 500 ? 1 : 3 }} />

          {/* {!allSelected ? (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ borderRadius: 12, position: "relative" }}
              onClick={selectAllComments}
            >
              Select all
            </Button>
          ) : null} */}
{/* 
          {viewportWidth < 500 ? (
            <Tooltip title={numSelected < 20 ? "Export Comments As Image" : "Select less than 20 comments to export."}>
              <span>
            <IconButton
              onClick={goToExport}
              disabled={numSelected === 0 || numSelected > 20}
              sx={{ color: "#fff", pl: 2, pr: 2 }}
            >
              <IosShareOutlined />
            </IconButton>
            </span>
            </Tooltip>
          ) : (
            <Tooltip title={numSelected < 20 ? "Export Comments As Image" : "Select less than 20 comments to export."}>
              <span>
            <Button
            disabled={numSelected === 0 || numSelected > 20}
              onClick={goToExport}
              sx={{ color: "#fff", pl: 2, pr: 2 }}
              endIcon={<IosShareOutlined />}
            >
              Export{" "}
            </Button>
            </span>
            </Tooltip>
          )} */}
        </Box>
      {/* </Popover> */}
    </div>
  );
}
