import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Container,
    List,
    ListItem,
    ListItemText,
    Autocomplete,
    Checkbox,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    Add,
    AddAPhotoOutlined,
    AddCardOutlined,
    AddOutlined,
    AddPhotoAlternateOutlined,
    AddToPhotosOutlined,
    ArrowBackIosNewOutlined,
    ArrowForward,
    AutoAwesome,
    BarChart,
    Chat,
    CheckBoxOutlined,
    CheckCircleOutlineOutlined,
    Clear,
    Close,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DeleteForeverOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FilterList,
    FolderOutlined,
    GroupOutlined,
    Info,
    OpenInNew,
    PendingActionsOutlined,
    RedoOutlined,
    RefreshOutlined,
    Remove,
    RemoveCircleOutlineOutlined,
    UndoOutlined,
    UploadFileOutlined,
    VideoFileOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  

  import Functions from "./HelperFunctions";
import GroupView from "./GroupView";
import { formatData, formatFullDate, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import { getTeamUsageData } from "./HelperFunctions"
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { sanitizeInput, containsJSInjection } from "./Helpers";
import { set } from "lodash";
import { styled } from "@mui/material/styles";


const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT;
const ENDPOINT = process.env.REACT_APP_ENDPOINT;


export default function ProcessingPostsModal(props) {

  const resiftLink = async (link, teamId) => {
    let newLinks = await Functions.linkDropQueue([link], teamId, []);
  };

    const { open, softRefresh, refreshData, handleClose, type, name, teamData, isMobile, handleGroupChange, forwardToPost
    } = props;

    const modalStyle = {
      width: "100%",
      borderRadius: 0, // Adjust the border radius as needed
      backgroundColor: 'white',
      // padding: '20px',
      outline: 'none', // Remove the default outline
      position:"fixed",
      left: "50%",
      bottom:0,
      maxHeight: '80%',
      overflowY: 'auto',
      overflowX: 'hidden',
      transform: "translate(-50%, 0)",
    };
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            // gap: ".5rem",
            display:"flex",
            // flexDirection:"column",
            // justifyContent:"center",          
          }}
        >
          
          <Box sx={{width:"100%", maxWidth:1000, margin:"auto", display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:2, p:4}}>

            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-end", gap:2}}
                >
  
  <Button onClick={() => softRefresh()} startIcon={<RefreshOutlined/>} variant="outlined"  sx={{borderRadius:20, color:"#666", borderColor:"#666"}}>
        Refresh
        </Button>

                <Button onClick={handleClose} endIcon={<Close/>} >

                  Close

                </Button>

            </Box>


          {teamData && teamData.pendingPosts && teamData.pendingPosts.length > 0 ? (

            <>


<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
    <Box sx={{display:"flex", flexDirection:"column"}}>
<Typography variant="h6" sx={{fontWeight:700, color:"#666" }}>
            Your posts are being processed
            <br/>
            <Typography variant="body2" sx={{fontWeight:400, color:"#666" }}>
                <Info fontSize="small" sx={{mr:1, color:"#666", height:16}}/>
                This typically takes from 10 minutes up to a few hours depending on the posts and platform
            </Typography>   
        </Typography>
        </Box>
        <Box sx={{flexGrow:1}}/>

        </Box>

<br/>

<Accordion elevation={0} defaultExpanded>
<AccordionSummary expandIcon={<ExpandMore />}>
<Box
sx={{
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-stretch",
  flexGrow: 1,
}}
> 


<Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-stretch",
        flexGrow: 1,
        gap:2,
        mb:2,
      }}

    >
        

<Typography variant="body1" sx={{fontWeight:700, color:"#666" }}>
Posts ({teamData.pendingPosts.length})

</Typography>

</Box>

</Box>

</AccordionSummary>

<AccordionDetails>

<Box sx={{display:"flex", flexDirection:"column", gap:2, width:"100%"}}>
{teamData.pendingPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).
map((post) => (
  <ProcessingPost post={post} softRefresh={softRefresh}
  resiftLink={resiftLink}
  forwardToPost={forwardToPost}
  handleClose={handleClose}
  groupLabels={post.groupIds && post.groupIds.length > 0 ?
    post.groupIds.map((groupId) => 
      // Fixing the return statement inside the map function
      teamData.groups.find((group) => group.id === groupId)?.name
  ) : []}

  />
))}

</Box>

</AccordionDetails>

</Accordion>

</>
          )

: 


<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:"100%", mt:4, mb:4}}>
<Box sx={{display:"flex", flexDirection:"column", gap:2}}>
<Typography variant="h6" sx={{fontWeight:700, color:"#666" }}>
       <CheckCircleOutlineOutlined sx={{color:"#666", height:24, width:24, mr:1}}/>
       Up to date
        <br/>
        <Typography variant="body2" sx={{fontWeight:400, color:"#666" }}>
            You have no posts processing.
        </Typography>   
    </Typography>
    </Box>
    </Box>
    

}


   </Box>
        </Box>
        
      </Modal>
    );
  }

  const ProcessingPost = ({ post, groupLabels, softRefresh, resiftLink, forwardToPost, handleClose}) => {

    const [status, setStatus] = useState('Waiting for updates...');
    let ws;

    const [updatedPost, setUpdatedPost] = useState(post);
    const [socket, setSocket] = useState(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const MAX_RECONNECT_ATTEMPTS = 5;

    React.useEffect(() => {
      setUpdatedPost(post);
    }, [post]);
  
  
    const connectWebSocket = useCallback(() => {
      
    const ws = new WebSocket(`${WSS_ENDPOINT}?postId=${updatedPost.id}`);
  
      ws.onopen = () => {
        console.log('WebSocket connected');
        setReconnectAttempts(0); // Reset attempts on successful connection

      };
  
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === 'postUpdate') {
          setUpdatedPost(data.post);
        }
      };
  
      ws.onclose = () => {
        console.log('WebSocket disconnected');
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          // Attempt to reconnect after a delay
          setTimeout(connectWebSocket, 3000);
          setReconnectAttempts(prevAttempts => prevAttempts + 1);
        } else {
          console.log('Max reconnection attempts reached');
        }
      };
  
      setSocket(ws);

      return () => {
        ws.close();
      }

    }, [updatedPost]);
  
    React.useEffect(() => {
      connectWebSocket();
  
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }, []);

    const stages = ["waiting", 'gathering', 'reading', 'analyzing', 'complete'];

    const [currentStage, setCurrentStage] = useState(1);
    const [progress, setProgress] = useState(0);

    let interval;

    React.useEffect(() => {
      if (updatedPost.status === 'complete') {
        setCurrentStage(4); // Set to 'complete' stage
        softRefresh();
        
      } 
      
      else if (updatedPost.status === 'processing:analyzing') {
        setCurrentStage(3); // Set to 'analyzing' stage
        clearInterval(interval);
        setProgress(0);
        interval = setInterval(() => {
        
          setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
          
        }, 1000);
      }
      else if (updatedPost.status === 'processing:reading') {
        setCurrentStage(2); // Set to 'reading' stage
        clearInterval(interval);
        setProgress(0);
        interval = setInterval(() => {
          if(progress >= 100){
            clearInterval(interval);
          }
          else{
            setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
          }        }, 1000);
      }
      else if (updatedPost.status === 'processing:gathering') {
        setCurrentStage(1); // Set to 'gathering' stage
        clearInterval(interval);
        setProgress(0);
        interval = setInterval(() => {
          if(progress >= 100){
            clearInterval(interval);
          }
          else{
            setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
          }        }, 1000);
      }
      else if (updatedPost.status === 'processing:waiting') {
        setCurrentStage(0); // Set to 'gathering' stage
        setProgress(0);
        // clearInterval(interval);
        // interval = setInterval(() => {
        //   if (progress >= 100) {
        //     clearInterval(interval);
        //   }
        //   else{
        //   setProgress((prevProgress) => (prevProgress + 5));
        //   }
        // }, 250);
      }

    }, [updatedPost]);

    const isMobile = window.innerWidth < 600;
  
    return (
      <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center"}}>
 
      <Box sx={{display:"flex", flexDirection:"column", gap:1, maxWidth:"60%"}}>
      <Link variant="body2" href={post.link} target="_blank" 
  
       //ellipsis if ismobile and link is too long
       sx={{flexGrow:1, overflow: "hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", maxWidth:isMobile ? 140 : "100%"}}
       >
        {updatedPost.link.replace(/(^\w+:|^)\/\//, '').replace("www.", "")}
      </Link>
      </Box>
      <Box sx={{flexGrow:1}}/>

      {
        groupLabels.map((label) => (
              <Chip 
              sx={{ backgroundColor: "#f5f5f5",  
                  color: "#62676a",
                  fontWeight: 500,
                  fontSize: 12,
                  height: 30}}
              label={label} />
        ))
        
      }

      {updatedPost.status && updatedPost.status != "fetching" && updatedPost.status != "processing" && updatedPost.status != "failed" && updatedPost.status != "complete" && (
        // <Typography variant="body2" color="#999">
        //   {updatedPost.status}
        // </Typography>
        <Box sx={{ width: "50%" }}>
        <StyledLinearProgress variant="determinate" value={currentStage * 25 + (progress/4)} />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
          {stages.map((stage, index) => (
            <Typography
              key={stage}
              variant="caption"
              sx={{
                color: index <= currentStage ? "primary.main" : "text.secondary",
                fontWeight: index === currentStage ? 700 : 400,
                textTransform: "capitalize",
              }}
            >
              {stage}
            </Typography>
          ))}
         
        </Box>
      </Box>
      )}
 {/* {(updatedPost.status == "failed" || updatedPost.status == "fetching") && (  
      <IconButton onClick={() => resiftLink(post.link, post.teamId)}  sx={{color:"#666", borderColor:"#666"}}>
      <RefreshOutlined/>
        </IconButton>
      )} */}
      
       {updatedPost.status == "failed" && (  
            <Typography variant="caption" sx={{ color: "error.main", fontWeight: 700 }}>
              Failed
            </Typography>
          )}

{updatedPost.status == "complete" && (  
  <Box sx={{display:"flex", flexDirection:"row", gap:1, alignItems:"center"}}>
            <Typography variant="caption" sx={{ color: "#069716", fontWeight: 700 }}>
              Complete
            </Typography>
            <CheckCircleOutlineOutlined sx={{color:"#069716", height:16, width:16}}/>
            <IconButton onClick={() => {
              
              forwardToPost(updatedPost.id);
              
              handleClose();}
              
              } size="small" sx={{color:"#666", borderColor:"#666"}}>
      <OpenInNew/>
        </IconButton>
            </Box>
          )}

    
      {updatedPost.createdAt && (
        <Typography variant="body2" color="#999">
          Uploaded: {formatFullDate(updatedPost.createdAt)}
        </Typography>
      )}

    </Box>
    );
  };


  const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    border:"transparent",
    [`&.MuiLinearProgress-colorPrimary`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .MuiLinearProgress-bar`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      // Add wave animation
      animation: 'waveAnimation 2s linear infinite',
      backgroundImage: `linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent 100%
      )`,
      backgroundSize: '40px 40px',
    },
    // Define keyframes for the wave animation
    '@keyframes waveAnimation': {
      '0%': {
        backgroundPosition: '0 0',
      },
      '100%': {
        backgroundPosition: '40px 0',
      },
    },
  }));