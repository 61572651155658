import { Box, Grid, Typography, Slider, Card, createTheme, ThemeProvider, ButtonBase, AppBar, Divider, Skeleton, Tabs, Tab, CardContent, AvatarGroup, Avatar } from '@mui/material';
import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { AutoAwesome, Comment, IndeterminateCheckBoxSharp, Person, PersonOutline, PersonPinCircle, PersonTwoTone } from '@mui/icons-material';
import axios from 'axios';
import TikTokComment from './CommentComponent';
import { siftsyTheme } from './siftsytheme';
import { useParams } from 'react-router-dom';
import { useAuth, UserButton, useUser } from "@clerk/clerk-react";
import { sifterOptions } from './constants';
import TikTokConnectionModule from './TikTokConnectionModule';
import Functions from './HelperFunctions';
import ConnectionModule from './ConnectionModule';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const darkMode = false;

const theme = createTheme(siftsyTheme);

const StyledButton = muiStyled(ButtonBase)(({ theme }) => ({
    borderRadius: 30,
    background: `${theme.palette.primary.main}`,
    fontFamily: [
        "Gilroy",
        'sans-serif',
    ].join(','),
    fontSize: '1rem',
    fontWeight: 900,
    position: "fixed",
    bottom: 20,
    left: "calc(50% - 85%/2)",
    color: theme.palette.secondary.main,
    padding: "15px 0px",
    width:"85%",
    boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.5)',
   
}));

const StyledCallout = muiStyled(Card)(({ theme }) => ({
    borderRadius: 30,
    background: `${theme.palette.primary.main}`,
    fontFamily: [
        "Gilroy",
        'sans-serif',
    ].join(','),
    fontSize: '1rem',
    fontWeight: 900,
    color: theme.palette.secondary.main,
    padding: "15px 0px",
    width: "fit-content",
    elevation: 0,
}));




export default function ConnectAccountPage(props) {

    const [sifts, setSifts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selected, setSelected] = React.useState(null);
    const [userData, setUserData] = React.useState(null);
    const [accounts, setAccounts] = React.useState([]);
    const [hasAccount, setHasAccount] = React.useState(false);

    const { isLoaded, isSignedIn, user } = useUser();

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    window.addEventListener('resize', () => {
        setPageWidth(window.innerWidth);
    });

    React.useEffect(() => {

        async function fetchData() {

            let loadedData = await Functions.fetchUser(user.emailAddresses[0].emailAddress);

            let owned = await Functions.listOwnedByEmail(user.emailAddresses[0].emailAddress)
            owned = owned.filter((account) => !account.subscribers.includes(user.emailAddresses[0].emailAddress));
            let subs = await Functions.listSubscriptionsByEmail(user.emailAddresses[0].emailAddress);
            let accounts = owned.concat(subs);
            setAccounts(accounts);
            setHasAccount(accounts.length > 0);

            setUserData(loadedData);

            const res = await Functions.listSiftsByEmail(user.emailAddresses[0].emailAddress);

            if (res) {
                let sifts = [];
                for(let i = 0; i < res.sifts.length; i++){
                    if(new Date(res.sifts[i].createdAt) > new Date("2023-08-30T00:00:00.000Z")){
                        sifts.push(res.sifts[i]);
                    }
                }

                sifts.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                
                setSifts(sifts);
            }
            setLoading(false);
        }

        fetchData();

    }, []);

    return (

        <div id="popup" style={{
            margin: "0 auto", width: pageWidth > 450 ? 450 : pageWidth, height: "auto",
            overflowX: "hidden"

        }}>

            <ThemeProvider theme={theme}>


                <AppBar position="static" elevation={0} sx={{ background: "transparent" }}>

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-stretch", alignItems: "center", m: 1, cursor:"pointer" }}
                    >

                        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}} onClick={() => { window.location.href = "/" }}>
                        <img src={"/siftsy_logo.png"} alt="logo" style={{ width: 80, height: "auto", margin: "5px" }} />
                        <Typography color="primary" align="center" variant={"subtitle2"}
                    sx={{borderRadius:30, background : "rgba(238,234,255, .8)", color: "#4828C5", padding:"1px 12px 1px 12px", ml:-1,
                      fontWeight: 600 }} >
                      beta </Typography>
                      </Box>

                        <Box sx={{ flexGrow: 1 }} />
                        

                        <Box sx={{ m: 1 }}>
                          <UserButton 
                          appearance={{
                            colorPrimary:"#4727C3",
          fontFamily: "Tahoma, sans-serif",
          fontFamilyButtons: "Tahoma, sans-serif",
                          }}
                          />
                        </Box>
                    </Box>



                </AppBar>


                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", m: 2, ml:3, mr:3 }}>

                    <Card elevation={0} sx={{ borderRadius: "12px", background: "#EEEAFF", p:2, width:"100%" }}>

                    {loading ?

                        <Skeleton variant="rectangular" width="100%" height={25} sx={{borderRadius:5}}/>

                    : hasAccount ?

                        <Typography align="center" variant="body1" fontWeight={600}>

                           Connect another TikTok account to <b>siftsy</b>.
                        
                        </Typography>

                        :
                        <Typography align="center" variant="body1" fontWeight={600}>

                         Welcome to <b>siftsy</b>{userData && userData.name ? `, ${userData.name.split(" ")[0]}!` : "!"} 
                                <br/>
                                Get started by connecting your TikTok.
                            
                        </Typography>
}

                    </Card>

                    </Box>


                <ConnectionModule />
                
                {/* <TikTokConnectionModule /> */}


            </ThemeProvider>
        </div>
    )
}

