// AccountSetupPage.js
import React, { useState } from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Box, Typography, Link, ButtonBase, LinearProgress, Modal, Dialog, Card, Grid, Skeleton } from '@mui/material';
import { set } from 'lodash';
import { Add, ArrowBackIos, ArrowForward, Check, CheckCircle, CheckCircleOutline, Close, CloseOutlined } from '@mui/icons-material';
import { useUser } from '@clerk/clerk-react';
import { styled as muiStyled } from '@mui/material/styles';
import Functions from './HelperFunctions';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const ConnectionModule = () => {
  const [username, setUsername] = useState('');
  const [userRole, setUserRole] = useState('owner'); // Default to creator
  const [submitted, setSubmitted] = useState(false);
  const [isContentLoaded, setContentLoaded] = useState(false);
  const [accountValid, setAccountValid] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const { isLoaded, isSignedIn, user } = useUser();
  const [confirm, setConfirm] = useState(false);

  const handleLoad = () => {
    setContentLoaded(true);
  };

  const handleReset = () => {
    setUsername('');
    setUserRole('owner');
    setSubmitted(false);
    setConfirm(false);
    setAccountValid(false);
  };

  const handleusernameChange = async (event) => {
    let username = event.target.value.replace("@", "").trim();
    setUsername(username);
    setAccountValid(username !== "");    

    // Check if account exists
    const exists = await Functions.checkAccount(`@${username}`, "tiktok");
    setAccountExists(exists);
  };

 

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Submitted:', { username, userRole });
    addAccount("@"+username, "tiktok", user.primaryEmailAddress.emailAddress, userRole);

    setSubmitted(true);    
  };

  return (
    <Box sx={{ml:3, mr:3}}>


<Box sx={{m:2, mt:2, mb:1}}>

<Box sx={{m:4}}>
<LinearProgress variant="determinate" value={confirm ? 66 : 33}
    />
    </Box>

   

{submitted ? 


<ReviewingModal reviewing={submitted} connectAccount={handleReset} username={username}/>

:
<Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:"1em"}}>

<Typography variant="h6" align="left" fontSize={"1.5rem"}>{confirm ? "Confirm TikTok Account" : "Connect TikTok Account"}</Typography>
</Box>
}

</Box>
   
      <form onSubmit={handleSubmit}>

         {!confirm ?   
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-stretch", alignItems:"center", gap:"1em"}}>

        <TextField
          label="Username"
          placeholder='@username'
          fullWidth
          margin="normal"
          value={"@" + username.replace("@", "")}
          onChange={handleusernameChange}
          InputLabelProps={{style: {color:"#4728c4", fontWeight:700}}}
          inputProps={{sx: {
            fontSize:"1.2rem",
            textTransform: 'lowercase',
             fontWeight:700,
               border:"2px solid #eee",
                borderRadius:"12px",
                padding:".5em",}}}
        />
       
        {(!accountValid || accountExists) ? 
            <CloseOutlined color="error"/> : 
            <CheckCircleOutline color="success"/>
        }

</Box>

        :
            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:".1em"}}>
        <Typography variant="body1" align="center" color="primary" fontWeight={700}>
        Username
        </Typography>
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:"1em"}}>
        <ButtonBase onClick={() => window.open("https://tiktok.com/@" + username)}>
            <Typography variant="h6" align="center" style={{cursor:"pointer", textTransform:"lowercase", fontWeight:700}}>
            {"@" + username}
            </Typography>
        </ButtonBase>
        {!accountValid ? 
            <CloseOutlined color="error"/> : 
            <CheckCircleOutline color="success"/>
        }
        </Box>
        </Box>

        }

      

      


        <FormControl component="fieldset" fullWidth margin="normal">

        <Typography variant="body1" align="left"><b>I am this account's...</b></Typography>

          <RadioGroup value={userRole} onChange={handleUserRoleChange}
      
          >
            <Grid container>
              <Grid item xs={6}>
                <Box sx={{display:"flex", justifyContent:"flex-start"}}>
            <FormControlLabel value="owner" control={<Radio />} label="Creator/Owner" disabled={confirm}/>
            </Box>
            </Grid>
            <Grid item xs={6}>
            <Box sx={{display:"flex", justifyContent:"flex-start"}}>
            <FormControlLabel value="manager" control={<Radio />} label="Manager" disabled={confirm}/>
            </Box>
            </Grid>
            <Grid item xs={6}>
            <Box sx={{display:"flex", justifyContent:"flex-start"}}>
            <FormControlLabel value="advertiser" control={<Radio />} label="Advertiser" disabled={confirm}/>
            </Box>
            </Grid>
            <Grid item xs={6}>
            <Box sx={{display:"flex", justifyContent:"flex-start"}}>
            <FormControlLabel value="other" control={<Radio />} label="Other" disabled={confirm}/>
            </Box>
            </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>

        <Box sx={{ m: 2, gap:"1rem", display:"flex", justifyContent:"center" }} >
            {confirm ?
            <>
               <OutlinedStyledButton onClick={() => setConfirm(false)}  disabled={!accountValid} >
            Back
        </OutlinedStyledButton>
        <StyledButton type="submit" disabled={!accountValid} >
          Confirm
        </StyledButton>
        </>

            :
            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5em"}}>

        <StyledButton onClick={() => setConfirm(true)} fullWidth disabled={!accountValid || accountExists}>
            Connect Account
        
      </StyledButton>

                {accountExists ?
                  <>
                <Typography variant="body1" align="center">
                This account is already connected to siftsy.<br/>Contact the account owner to request access.
                </Typography>
                </>
                : null
                }
                          
                </Box>
            }
        </Box>
      </form>
  
    

       </Box>
  );
};

export default ConnectionModule;


async function addAccount(username, platform, email, userRole){

    let req = {
      username: username,
        platform: platform,
        email:email,
        registeredBy:userRole
    };
  
    return await fetch(`${ENDPOINT}/registeraccount`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
  
    .catch((err) => console.log(err));
  
  }


  async function removeAccount(handle, platform, email){

    let req = {
        handle: handle,
        platform: platform,
        email:email
    };
  
    return await fetch(`${ENDPOINT}/removeaccount`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
  
    .catch((err) => console.log(err));
  
  }

  const StyledButton = muiStyled(ButtonBase)(({ theme }) => ({
    borderRadius: 30,
    background: `${theme.palette.primary.main}`,
    fontFamily: [
        "Gilroy",
        'sans-serif',
    ].join(','),
    fontSize: '1.2rem',
    fontWeight: 900,
    width:"100%",
    color: theme.palette.secondary.main,
    padding: ".8rem 1.2rem",
    boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.5)',
    "&.Mui-disabled": {
      background: "#eee",
      color: "#858585",
      boxShadow: 'none',
  },
}));

const OutlinedStyledButton = muiStyled(ButtonBase)(({ theme }) => ({
    borderRadius: 30,
    background: `${theme.palette.secondary.main}`,
    fontFamily: [
        "Gilroy",
        'sans-serif',
    ].join(','),
    fontSize: '1.2rem',
    fontWeight: 900,
    width:"100%",
    color: theme.palette.primary.main,
    padding: ".8rem 1.2rem",
    border: "2px solid " + theme.palette.primary.main,
    boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.2)',
    "&.Mui-disabled": {
      background: "#eee",
      color: "#858585",
      boxShadow: 'none',
  },
}));

const StyledModal = muiStyled(Modal)(({ theme }) => ({
    modal: {
      userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        },
        paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: "1em",
        width: "50%",
        height: "50%",
        },

  }));
  
  // Modal component
  export function ReviewingModal(props) {
    const { reviewing, username, connectAccount } = props;
    const [open, setOpen] = useState(reviewing);
    const [loading, setLoading] = useState(true);

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = 'body { pointer-events: none; }';
  
    React.useEffect(() => {
  
        // Create a style element and append it to the head
        if (reviewing){
        setOpen(reviewing);
        document.head.appendChild(style);

        setTimeout(() => {
        setLoading(false);
        }, 250);  

        }
    
        // Clean up the style element when the component unmounts
        return () => {
          if (document.head.contains(style)){
          document.head.removeChild(style);
        }
        };
      }, [reviewing]); // Empty dependency array to run the effect only once


    return (
   
        <StyledModal
          open={open}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick={true}

        >
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:"1em", m:4}}>
        <Card sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1em", width:"fit-content", margin:"auto auto", borderRadius:12}}>
          <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1em", m:2, mt:5}}>
            
            <Typography variant="h6" align="center" fontSize={"1.3rem"}>Connection was successful!</Typography> 

           <Box sx={{height:"8em"}}>
            <img src={"/confirmed.gif"} alt="logo" style={{ width:"8em", height: "auto" }} />
            </Box>
            

            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:".1em"}}>
        <Typography variant="body1" align="center" color="primary" fontWeight={600}>
        Username
        </Typography>
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:".5em"}}>
        {loading ?
        
        <Skeleton width={80} height={25} sx={{borderRadius:5}}/>
        
        :
        <ButtonBase onClick={() => window.open("https://tiktok.com/@" + username)}>
        
            <Typography variant="h6" align="center" style={{cursor:"pointer", textTransform:"lowercase", fontWeight:600}}>
            {"@" + username}
            </Typography>
          
        </ButtonBase>
       }
            </Box>



            <br/>
          <Typography variant="h6" align="center">
            We are reviewing your account.
            </Typography>
            <Typography variant="body1" align="center">
            You'll receive an email once approved.
            </Typography>

          <Box sx={{ mt: 5, mb:5}} >
            
          <OutlinedStyledButton onClick={connectAccount} sx={{pointerEvents:"auto"}}>
           Connect Another Account
          </OutlinedStyledButton>
            <br/><br/>
          <Typography variant="body1" align="center">
            <i>Or you can close this tab.</i>
            </Typography>

        
        </Box>
        
        </Box>
        



            </Box>
         </Card>
         </Box>
        </StyledModal>
     
    );
  }
  