import React, { useState } from "react";
import {
    Autocomplete,
  Box,
  ButtonBase,
  Chip,
  ClickAwayListener,
  FormControl,
  IconButton,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Check, Close } from "@mui/icons-material";
import HelperFunctions from "./HelperFunctions";

const ContentTagPicker = (props) => {
  const Functions = HelperFunctions();
  const { deleteable, cellView, post, refreshData, softRefresh, teamData } =
    props;
  const [newGroupName, setNewGroupName] = useState("");
  const [cellViewOpen, setCellViewOpen] = useState(false);

  const cellRef = React.useRef(null);

  let tagOptions =
    teamData &&
    teamData.posts &&
    teamData.posts
      .map((post) => post.data.tags ? JSON.parse(post.data.tags) : null)
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index);

  const [selectedTags, setSelectedTags] = useState(
    props.post.data.tags ? JSON.parse(props.post.data.tags) : []
  );

  const handleSelectedTagChange = async (event, newValue) => {
    console.log("newValue", newValue);
    const tag = newValue.props.value;
    //if group is not in selectedGroups, add it, else remove it

    let tags = selectedTags.push(tag);

    setSelectedTags(tags);

    console.log("selectedTags", selectedTags);
  };
    
  const updatePostTags = React.useCallback(async (tags) => {
      let updatedPost = await Functions.updatePostTags(post.id, teamData.id, tags);
    softRefresh();
  }, [post, teamData]);

    
  const autocompleteComponent = (
    <Autocomplete
      freeSolo
      multiple
      clearOnEscape={false}
      clearIcon={null}
      fullWidth
      value={selectedTags}
      onChange={(event, newValue) => {
        setSelectedTags(newValue);
        updatePostTags(newValue);
        // if(!cellView){
        // updatePostTags(newValue);
        // }
      }}
    //   onClose={() => {
      
    //     updatePostTags(selectedTags);
        
    //     }}
      options={tagOptions.sort((a, b) => (b && a) ? -b.localeCompare(a) : 0)}
      getOptionLabel={(option) => option}
      renderTags={(value, getTagProps) =>
        <Box sx={{ marginBottom: 1}}>

    {value.map((option, index) => (
          <Chip
            sx={{    backgroundColor: "#f5f5f5",
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30
            }}
            key={option}
            label={option}
            deleteIcon={deleteable ? <Close /> : null}
            onDelete={
              deleteable
                ? () =>
                    setSelectedTags(
                      selectedTags.filter((tag) => tag !== option)
                    )
                : null
            }
            {...getTagProps({ index })}
          />
        ))}
        </Box>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          sx={{
            border: "0px",
            background: "transparent",
            color: "#999",
            fontWeight: 600,
            fontSize: 14,
          }}
          placeholder=" Select tags or type in a new one"
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && event.target.value.trim() === '') {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Chip
            sx={{ backgroundColor: selected ? "#f5f5f5" : "white",  
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30}}
            label={option}
            variant="outlined"
            icon={selected ? <Check /> : null}
          />
        </li>
      )}
    />
  );

  return (
  
  <Box sx={{ display: "flex", flexDirection:"row", gap: 1, width:"100%" }} ref={cellRef}>
  {cellView && !cellViewOpen ? (
    <ButtonBase onClick={() => setCellViewOpen(true)}>
      <>
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
          <Chip
            sx={{ backgroundColor: "#f5f5f5",
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30 }}
            label={
             selectedTags && selectedTags.length > 0 && selectedTags[0]
            }
          />
          
        </Box>
        <Typography sx={{ fontSize: 14, m: 1, fontWeight: 600 }}>
          {selectedTags.length > 1 && `+ ${selectedTags.length - 1}`}
        </Typography>
      </>
    </ButtonBase>
  ) : (
    <Box sx={{ display: "flex", flexDirection:"row", gap: 1, width:"100%" }}>
        {cellViewOpen ? <CustomPopper cellRef={cellRef} setCellViewOpen={setCellViewOpen} cellViewOpen={cellViewOpen}>{autocompleteComponent}</CustomPopper> : autocompleteComponent}
    </Box>
    )}
  </Box>

  );
};

export default ContentTagPicker;

const CustomPopper = (props) => {
    const [isOpen, setIsOpen] = useState(props.cellViewOpen);

    React.useEffect(() => {
      setIsOpen(props.cellViewOpen);
    }, [props.cellViewOpen]);
  
    const handleClickAway = () => {
      setIsOpen(false);
      props.setCellViewOpen(false);
    };
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
        {...props}
        open={isOpen}
        anchorEl={props.cellRef && props.cellRef.current ? props.cellRef.current : document.body}
        placement="bottom"
        style={{
          background: 'white',
          minWidth:200
        }}
      >
        <Box sx={{ p: 1, background: 'white', borderRadius: 2, boxShadow: 2, maxWidth:300 }}>
        {props.children}
        </Box>
      </Popper>
      </ClickAwayListener>
    );
  };
